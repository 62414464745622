import styled from "styled-components";

export const WheelCreatorSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .wheel-title {
    display: flex;
    align-items: center;
  }
  .cretor-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 350px;

    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 12px;

    overflow: auto;
  }
  .creator-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    min-height: 35px;
    width: 100%;
  }
  .creator-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
  }
  input {
    display: flex;
    align-items: center;
    height: 35px;
    min-height: 35px;
    width: 100%;

    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 0 12px;

    outline: none;
  }
`;
