import React from "react";

const EnrollIcon = ({ size = 40, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-120 -120 1100 1100"
    fillRule="evenodd"
    clipRule="evenodd"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    width={size}
    height={size}
    fill={fill}
  >
    <path
      d="M416 85c233 0 233 355 0 355S183 85 416 85zM102 759c25-397 619-399 642 1l-37 2c-20-353-546-352-567-1l-37-2zm314-636c-184 0-184 279 0 279s184-279 0-279z"
      fillRule="nonzero"
    />
    <rect width="50" height="220" x="740" y="0" />
    <rect width="220" height="50" x="660" y="80" />
  </svg>
);

export default EnrollIcon;
