import React from "react";

const CalendarIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-12 -12 125 125"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M83.333 10H16.667C13 10 10 13.001 10 16.667v66.667C10 86.999 13 90 16.667 90h66.667C86.999 90 90 86.999 90 83.333V16.667C90 13.001 86.999 10 83.333 10zm0 6.667v6.667H16.667v-6.667h66.666zM16.667 83.333V30h66.667v53.333H16.667z" />
    <path d="M26.667 66.667h6.667v6.666h-6.667zM40 66.667h6.667v6.666H40zM53.333 66.667H60v6.666h-6.667zM26.667 53.333h6.667V60h-6.667zM40 53.333h6.667V60H40zM53.333 53.333H60V60h-6.667zM66.667 53.333h6.666V60h-6.666zM26.667 40h6.667v6.667h-6.667zM40 40h6.667v6.667H40zM53.333 40H60v6.667h-6.667zM66.667 40h6.666v6.667h-6.666z" />
  </svg>
);

export default CalendarIcon;
