import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { CreatorUploadFormSec2Styled } from './styled';
// import { iconImages } from 'assets'
import { VALIDATE } from 'helpers';
import { ButtonCustom, InputUploadFIle, Card } from 'components';

class CreatorUploadFormSec2 extends React.Component {
  state = {};

  uploadFile = (file) => {
    this.setState({
      uploadFile: true,
    })
  }

  render() {
    const { handdleSave, handleSubmit, initialValues, loading } = this.props;
    const { uploadFile } = this.state;
    return (
      <CreatorUploadFormSec2Styled>
        <form onSubmit={handleSubmit(handdleSave)}>
          <Card title="Creator Poster">
            <div className="item_layout">
              <Field
                theme_standard
                name="photo_file"
                accept="image/x-png,image/gif,image/jpeg"
                buttonLabel="Picture"
                component={InputUploadFIle}
                width={190} height={250}
                validate={VALIDATE.REQUIRE}
                title="Photo"
                haveFilePhoto={initialValues && initialValues.photo_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
            <div className="item_layout">
              <Field
                theme_standard
                name="video_file"
                accept="video/mp4,video/x-m4v,video/*"
                buttonLabel="Video"
                component={InputUploadFIle}
                width="300" height="240"
                title="Video"
                haveFileVideo={initialValues && initialValues.video_file ? true : false}
                onChange={this.uploadFile}
              />
            </div>
            <div className="button_layer">
              <ButtonCustom
                theme_yellow_2
                label={loading ? "LOADING" : "SAVE"}
                uploadFile={uploadFile}
                disabled={uploadFile ? loading ? true : false : true}
              />
            </div>
          </Card>
        </form>
      </CreatorUploadFormSec2Styled>
    );
  }
}

export default reduxForm({
  form: 'CreatorUploadFormSec2', // a unique identifier for this form
  enableReinitialize: true,
})(CreatorUploadFormSec2);
