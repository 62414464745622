import React from "react";
import { connect } from "react-redux";
import { CreatorDetailContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import { ROUTE_PATH } from "helpers";
import {
  CreatorGenreContainer,
  CreatorProfileContainer,
  CreatorUploadContainer,
  CreatorServicesContainer,
} from "../SectionsCreatorDetail";

class CreatorDetailContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();

  }
  fetchData = () => {
    if (this.props.match.params.id !== "create") {
      this.setState({
        creatorID: this.props.match.params.id,
      });
    }
  };

  callback = (e) => {
    this.props.history.push(ROUTE_PATH.A_CREATOR + "/" + e);
    this.setState({
      creatorID: e,
    });
  };

  render() {
    const { creatorID } = this.state;
    return (
      <CreatorDetailContainerStyled>
        <div className="form-wrapper">
          <div className="form_item_layout">
            <CreatorProfileContainer
              creatorID={
                this.props.match.params.id === "create"
                  ? undefined
                  : this.props.match.params.id
              }
              callback={this.callback}
            />
          </div>
          {creatorID && (
            <>
              <div className="form_item_layout">
                <CreatorUploadContainer creatorID={creatorID} />
              </div>
              <div className="form_item_layout">
                <CreatorGenreContainer creatorID={creatorID} />
              </div>
              <div className="form_item_layout last">
                <CreatorServicesContainer creatorID={creatorID} />
              </div>
            </>
          )}
        </div>
      </CreatorDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorDetailContainer);
