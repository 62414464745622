import styled from "styled-components";
import { COLOR } from "assets";

export const AgencyFormAgentStyled = styled.div`
  input[type="text"] {
    width: 100%;
    height: 35px;

    border-radius: 4px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 8px;
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
  }
  .title {
    margin-bottom: 1rem;
    font-size: 20px;
  }
  .item_init {
    .label {
      span {
        margin-left: 5px;
        color: ${COLOR.RED_1};
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .box_label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .label {
      min-width: 100px;
      margin-right: 10px;
    }
    .item_checkout {
      margin-right: 8px;
    }
  }
  .group_show {
    margin: 1rem 0;
  }
  .button_layer {
    text-align: right;
  }
`;
