import React from "react";

const CrossIcon = ({ size = 40, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-110 -110 550 550"
    fillRule="evenodd"
    clipRule="evenodd"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    width={size}
    height={size}
    fill={fill}
  >
    <path d="M319 45L196 167l123 122c19 19-11 49-30 30L167 196 45 319c-20 19-50-11-30-30l122-122L15 45C-5 25 25-5 45 15l122 122L289 15c19-20 49 10 30 30z" />
  </svg>
);

export default CrossIcon;
