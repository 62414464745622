import styled from "styled-components"
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const BoxShowStyled = styled.div`
/*===============================================
 Container 
===============================================*/
    
/*===============================================
Theme 
===============================================*/
border: 1.1px solid;
border-radius: 5px;
padding: 8px;
overflow : scroll;
max-height : 160px ;
    .theme_standard {
        .group_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            :last-child {
                margin-bottom: 0px;
            }

        }
    }
`