import React from "react";

const VoteIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 -2 30 30"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M19.71 9H16l.58-2.32a1.87 1.87 0 00-1.223-2.228h-.002a1.87 1.87 0 00-2.147.736L12 7l-3 3V9H5.25A1.236 1.236 0 004 10.222v8.556A1.236 1.236 0 005.25 20H9v-1l3.462 1h5.02a3.462 3.462 0 003.435-3.032l.775-5.699A2 2 0 0019.71 9zM5.25 19a.238.238 0 01-.25-.222v-8.556A.238.238 0 015.25 10H8v9zm14.675-2.156A2.465 2.465 0 0117.483 19h-4.88l-3.325-.96-.136-.04H9v-7h.414l.293-.293 3-3 .07-.07.055-.082 1.208-1.812a.871.871 0 011.57.694l-.58 2.32L14.72 10h4.99a1 1 0 01.99 1.135z" />
  </svg>
);

export default VoteIcon;
