import React from "react";

const CategoryIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-150 -100 2800 2800"
    fillRule="evenodd"
    clipRule="evenodd"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    height={size}
    width={size}
    fill={fill}
  >
    <path
      d="M1472.94 316.32h-465.51c-27.61 0-50 22.39-50 50v465.51c0 27.61 22.39 50 50 50h183.42l7.99 1009.27-238.2-.01v-182.94c0-27.61-22.39-50-50-50h-465.5c-27.61 0-50 22.39-50 50v465.5c0 27.61 22.39 50 50 50h465.5c27.61 0 50-22.39 50-50v-182.96l288.79.01c27.5-.21 49.62-22.69 49.41-50.19l-5.07-640.25h285.56v182.76c0 27.61 22.39 50 50 50h465.51c27.61 0 50-22.39 50-50v-465.51c0-27.61-22.39-50-50-50h-465.51c-27.61 0-50 22.39-50 50v182.75h-286.36l-2.52-318.43h182.49c27.61 0 50-22.39 50-50V366.32c0-27.61-22.39-50-50-50zm-977.8 1441.83h365.5v365.5h-365.5v-365.5zm1184.19-690.64h365.51v365.51h-365.51v-365.51zm-621.9-651.19h365.51v365.51h-365.51V416.32z"
      fillRule="nonzero"
    />
  </svg>
);

export default CategoryIcon;
