import React from 'react'
import { BoxShowStyled } from './styled'
import cx from "classnames";
import { ButtonCustom } from 'components'

export const BoxShow = ({ theme_standard, data, handdleClick }) => {
  const customClass = cx({
    "theme_standard": theme_standard
  })
  if (data && data.length === 0) {
    return <span>-</span>
  } else {
    return (
      <BoxShowStyled>
        <div className={customClass}>
          {
            data &&
            data.map((e, i) => (
              <div key={i} className="group_box">
                <div>
                  {e.displayname}
                </div>
                <ButtonCustom theme_white label="REMOVE" onClick={() => handdleClick(e.id)} />
              </div>
            ))
          }
        </div>
      </BoxShowStyled>
    )
  }
}