import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'actions';
import { ButtonCustom } from 'components'
import { ROUTE_PATH } from 'helpers';
const Unauthorized = (props) => {
  const dispatch = useDispatch();

  const handleClickLogout = useCallback(async () => {
    let res = await userService.GET_LOGOUT();
    if (res && res.success) {
      localStorage.removeItem('wowToken');
      sessionStorage.removeItem('wowLogin');
      dispatch(setReduxUserAuth(false));
    }
    props.history.push(ROUTE_PATH.LOGIN);
    dispatch(setReduxUserAuth(false));
  }, [dispatch, props.history]);

  return (
    <center style={{ marginTop: '13%' }}>
      <div style={{ padding: 20, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', width: '40%' }}>
        <h1> Please Authorized Your account</h1>
        <ButtonCustom theme_yellow_2 label="Logout" onClick={() => handleClickLogout()} />
      </div>
    </center>
  )
}
export default Unauthorized;