import { createGlobalStyle } from "styled-components";
import {
  // FONT,
  COLOR,
} from "../constant";

export const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
	}
	body {
		margin: 0;
		font-family: "unicaOne_regular" , "prompt_light";
		color: ${COLOR.BLACK_1};
		button {
			font-family: "unicaOne_regular" , "prompt_light";
			cursor: pointer;
			&:focus {
				outline: unset;
			}
		}
		select {
		font-family: "unicaOne_regular" , "prompt_light";
		}
		input {
			/* font-family: "unicaOne_regular" , "prompt_light"; */
		}
		textarea  {
			&:focus {
				outline: unset;
			}
		}
	}
`;
