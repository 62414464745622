import styled from "styled-components";
import { COLOR, SCREEN } from "assets";

export const CreatorContainerStyled = styled.div`
  width: 100%;
  .main_layout {
    .search_layer {
      display: flex;
      padding: 1rem 2rem;
      justify-content: space-between;
      align-items: center;
    }
    .creator_layer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-evenly;
      @media only screen and (max-width: ${SCREEN.PHONE}) {
        justify-content: center;
      }
    }
  }
  .scroll_top {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 8;
    background-color: ${COLOR.YELLOW_1};
    padding: 20px 20px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 0;
    cursor: pointer;
  }
  .Row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .button_add {
    /* @media only screen and (max-width: ${SCREEN.PHONE}) { */
    display: none;
    /* } */
  }
`;

export const CardContainer = styled.div`
 
  opacity : ${(props) => (props.options.is_active === 0 ? 0.5 : 1)};
  .creator_card_container {
    width : 450px;
    height : 200px;
    display: flex;
    margin: 10px 2rem 10px 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    cursor: pointer;
    border-radius : 15px;
    justify-content : flex-start;
    border : 3px solid ${COLOR.YELLOW_2};
    position : relative;
    @media only screen and (max-width: 1200px) {
      width : 375px;
    }
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      flex-direction : column;
      width : 220px;
      height : 250px;
      display : block;
      margin : 10px;
    }
  }

  .card_about{
    font-size: 14px;
    padding-top: 6px;
    @media only screen and (max-width: 1200px) {
    width: 90%;
    }
    @media only screen and (max-width: ${SCREEN.PHONE}) {
     display : none;
    }
  }
  .card_image_container{
    display : flex;
    margin-top : 15px;
    padding-left : 10px;
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      justify-content : center;
      align-items: center;
      padding-left : 0px;
      margin-top : 10px;
    }
  }
  .genre_header_mobile{
    display : none;
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      padding : 5px 5px 0px 10px;
      display : block;
   }
}
 .creator_card_container:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .card_content {
    padding: 5px 20px;
    display: flex;
    flex-direction : column;
    @media only screen and (max-width: 1200px) {
    width: 270px;
    }
  }

  .card_header_genre {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    height : 30px;
    @media only screen and (max-width: 1200px) {
    width: 100%;
    }
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      display : none;
    }
  }

  .card_option{
    display: flex;
    justify-content: flex-end;
    position : absolute;
    bottom: 0;
    right : 0;
    background-color : ${COLOR.YELLOW_2};
    width : 100%;
    border-radius : 0px 0px 10px 10px;
    padding-right : 20px;
  } 

  .card_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 25px;
    width : 300px;
    @media only screen and (max-width: 1200px) {
    width: 240px;
    }
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      width : 80%;
    }
  }
  .card_description{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width : 300px;
    @media only screen and (max-width: 1200px) {
    width: 240px;
    }
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      width : 80%;
    }
  }
  }
`;

export const OptionBullet = styled.span`
  margin: 5px;
  background-color: ${(props) => (props.is_option ? "white" : COLOR.BLUE_5)};
  border: ${(props) => (props.is_option ? `1px solid ${COLOR.BLUE_5}` : null)};
  color: ${(props) => (props.is_option ? "black" : "white")};
  border-radius: 50%;
  padding: 1px 7px;
  font-size: 13px;
`;

export const InputSearch = styled.div`
  /* width: 100%;
 @media only screen and (max-width: ${SCREEN.PHONE}) {
        width: 100%;
        pad
        ding-top :10px;
    } */
  /* width: 100%; */
  width: 50%;
  @media only screen and (max-width: ${SCREEN.PHONE}) {
    width: 100%;
  }
  .theme_mobile_standard {
    position: relative;
    .input {
      /* width: calc(100% - 40px); */
      width: 100%;
      padding: 9px 31px 9px 9px;
      border-radius: 15px;
      border: 1px solid #d9d9d9;
      outline: none;
    }
    .icon {
      width: 20px;
      position: absolute;
      top: 7px;
      right: 10px;
      cursor: pointer;
    }
  }
`;
