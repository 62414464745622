import React from "react";

const CouponIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-5 -8 60 60"
    height={size}
    width={size}
    fill={fill}
  >
    <g data-name="Layer 15">
      <path d="M43.18 20.619h2.144a1 1 0 001-1v-8.517a1 1 0 00-1-1H2.676a1 1 0 00-1 1v8.517a1 1 0 001 1h.797a2.526 2.526 0 012.493 2.492 2.496 2.496 0 01-2.493 2.493h-.797a1 1 0 00-1 1v10.294a1 1 0 001 1h42.648a1 1 0 001-1V26.604a1 1 0 00-1-1H43.18a2.466 2.466 0 01-2.493-2.493 2.496 2.496 0 012.493-2.492zm0 6.985h1.144v8.294H3.676V27.6a4.498 4.498 0 004.29-4.489 4.557 4.557 0 00-4.29-4.487v-6.522h9.212v1.01a1 1 0 002 0v-1.01h29.436v6.517H43.18a4.498 4.498 0 00-4.493 4.492 4.445 4.445 0 004.493 4.493z" />
      <path d="M13.888 16.506a1 1 0 00-1 1v2.011a1 1 0 002 0v-2.011a1 1 0 00-1-1zM13.888 22.91a1 1 0 00-1 1v2.01a1 1 0 002 0v-2.01a1 1 0 00-1-1zM13.888 29.314a1 1 0 00-1 1v2.011a1 1 0 002 0v-2.01a1 1 0 00-1-1z" />
    </g>
  </svg>
);

export default CouponIcon;
