import React, { useState } from "react";
import { connect } from "react-redux";
import { WheelContainerStyled, Table, ImagePreloadContainer } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { Helmet } from "react-helmet";
import { ButtonCustom, SideContainer, CreatorIcon } from "components";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import WheelCreator from "../WheelComponent/WheelCreator";
import WheelDetail from "../WheelComponent/WheelDetail";
class WheelContainer extends React.Component {
  state = {
    data: [],
    isOpenSideContainer: false,
    initialValues: null,
    type: null,

    sidePanelTitle: "New Wheel",
    sidePanelComponents: null,
    wheelData: {},
    creatorData: [],
    showCreatorData: false,
    wheelDetailSubmit: () => {},
    wheelDetailCancel: () => {},
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_WHEEL_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  createWheel = async(event) => {
    event.preventDefault();

    const form = { ...event };

    const data = this.createBodyDataForService(form);

    const { success } = await adminService.POST_WHEEL_LIST(data);
    if (success) {
      toast.success("สำเร็จ");
    } else {
      toast.error("ไม่สำเร็จ");
    }
  };

  handleCreateWheel = () => {
    this.setState({
      wheelData: {},
      sidePanelTitle: "Create Wheel",
      creatorData: [],
      showCreatorData: false,
      wheelDetailSubmit: this.createWheel,
      wheelDetailCancel: this.onCloseSidePanel,
    });

    this.onOpenSidePanel();
  };

  onOpenSidePanel = () => {
    this.setState({
      isOpenSideContainer: true,
    });
  };

  onCloseSidePanel = () => {
    this.setState({
      isOpenSideContainer: false,
    });
  };

  removeCreatorFromWheel = async (wheel_slug, creator_id) => {
    const { success } = await adminService.DELETE_CREATOR_WHEEL(
      wheel_slug,
      creator_id
    );
    if (success) {
      toast.success("ยกเลิกสำเร็จ");
    } else {
      toast.error("ยกเลิกไม่สำเร็จ");
    }
  };

  removeCreatorFromWheelHandler = (wheel_slug, creator_id) => {
    console.log(wheel_slug, creator_id);
    confirmAlert({
      title: "Confirm to toggle",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.removeCreatorFromWheel(wheel_slug, creator_id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  createBodyDataForService = (form) => {
    const data = {};

    for (let i = 0; i < form.target.length; i++) {
      const { type, name, value, checked } = form.target[i];
      if (type === "text") {
        data[name] = value;
      } else if (type === "number") {
        data[name] = parseFloat(value);
      } else if (type === "checkbox") {
        data[name] = checked;
      }
    }

    return data;
  };

  updateWheel = async (event, slug) => {
    event.preventDefault();

    const form = { ...event };

    const data = this.createBodyDataForService(form);

    const { success } = await adminService.PATCH_WHEEL_UPDATE(slug, data);
    if (success) {
      toast.success("สำเร็จ");
    } else {
      toast.error("ไม่สำเร็จ");
    }
  };

  handleClickWheel = async (wheelData) => {
    const { success, data } = await adminService.GET_CREATOR_WHEEL(
      wheelData.slug
    );

    if (success) {
      this.setState({
        wheelData: wheelData,
        sidePanelTitle: "Wheel Detail",
        creatorData: data,
        wheelDetailSubmit: this.updateWheel,
        wheelDetailCancel: this.onCloseSidePanel,
        showCreatorData: true,
      });

      this.onOpenSidePanel();
    }
  };

  render() {
    const { data } = this.state;
    return (
      <WheelContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>

        <>
          <div className="tab_layout">
            <div className="title_layout">Wheel System</div>
            <div className="button_layer">
              <ButtonCustom
                theme_yellow_2
                label="+ New Wheel"
                onClick={this.handleCreateWheel}
              />
            </div>
          </div>

          <div className="content">
            <Table>
              {data?.map((value, index) => (
                <label
                  className="genre-wrapper"
                  key={index}
                  onClick={() => this.handleClickWheel(value)}
                >
                  <div className="genre-container">
                    <ImageWithPreload src={value.poster_photo_uri} />
                    <div className="overlay">
                      <span className="genre-name">{value.title}</span>
                      <div className="creator-count-container">
                        <CreatorIcon fill="white" />
                        <span className="count">
                          {value.creators_count.toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <span className="ordering-number">#{value.order}</span>
                  </div>
                </label>
              ))}
            </Table>
          </div>
        </>

        <SideContainer
          title={this.state.sidePanelTitle}
          isShow={this.state.isOpenSideContainer}
          handleClose={() => this.onCloseSidePanel()}
        >
          <WheelDetail
            wheelData={this.state.wheelData}
            onSubmit={this.state.wheelDetailSubmit}
            onCancel={this.state.wheelDetailCancel}
            isShow={this.state.isOpenSideContainer}
          />

          <br />
          <div className="seperate-line" />
          <br />

          <WheelCreator
            showCreatorData={this.state.showCreatorData}
            creatorData={this.state.creatorData}
            onDelete={this.removeCreatorFromWheelHandler}
            wheelSlug={this.state.wheelData.slug}
          />
        </SideContainer>
      </WheelContainerStyled>
    );
  }
}

const ImageWithPreload = ({ src }) => {
  const [loading, setIsLoading] = useState(true);
  return (
    <ImagePreloadContainer>
      {loading && <div className="preload">loading</div>}
      <img src={src} alt="genre" onLoad={() => setIsLoading(false)} />
    </ImagePreloadContainer>
  );
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.WheelProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WheelContainer);
