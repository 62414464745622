import React, { useState, useCallback } from "react";
import { NavbarStyled, Dismiss } from "./styled";
import { iconImages } from "assets";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userService } from "apiService";
import { setReduxUserAuth } from "actions";
import { ROUTE_PATH } from "../../../helpers/constant/index";
import { HamburgerMenuIcon } from "components";
export const Navbar = ({ toggleHamburger }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const history = useHistory();

  const [dismiss, setDismiss] = useState(false);

  const onClickHome = () => history.push("/");

  const toggleProfile = () => setDismiss(!dismiss);

  const handleClickLogout = useCallback(async () => {
    let res = await userService.GET_LOGOUT();
    if (res && res.success) {
      localStorage.removeItem("wowToken");
      sessionStorage.removeItem("wowLogin");
      dispatch(setReduxUserAuth(false));
    }
    history.push(ROUTE_PATH.LOGIN);
    dispatch(setReduxUserAuth(false));
  }, [dispatch, history]);

  return (
    <NavbarStyled>
      <div className="hamburger-menu" onClick={toggleHamburger}>
        <HamburgerMenuIcon fill="rgba(0,0,0,0.5)" />
      </div>
      <label className="avatar">
        <img
          className="person"
          src={auth.avatar ? auth.avatar : iconImages["person.png"]}
          alt="profile"
        />
        <input type="checkbox" onChange={toggleProfile} />
        <div className="profile-menu-container">
          <div className="menu" onClick={onClickHome}>
            Home
          </div>
          <div className="menu" onClick={handleClickLogout}>
            Log out
          </div>
        </div>
        {dismiss && <Dismiss />}
      </label>
    </NavbarStyled>
  );
};
