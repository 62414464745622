import styled from "styled-components";
import { SCREEN } from "assets";

export const CmsMainlayoutStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  .main-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    margin-left: 60px;
    margin-top: 60px;

    overflow: hidden;
    .main-content {
      height: 100%;
      max-height: 100%;
      display: flex;

      font-family: prompt_light;
      overflow: auto;
    }
  }
  @media (max-width: ${SCREEN.TABLET}) {
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .main-body {
      margin-left: 0;
    }
  }
`;
