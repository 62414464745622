import React from "react";

const EmailIcon = ({ size = 40, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-8 -8 75 75"
    width={size}
    height={size}
    fill={fill}
  >
    <path d="M53.932 13.665c-.024-.067-.06-.124-.098-.184-.024-.038-.033-.082-.063-.118-.01-.012-.026-.017-.036-.03-.048-.052-.105-.09-.163-.131-.05-.036-.095-.075-.149-.1-.054-.026-.114-.036-.173-.051-.068-.018-.133-.038-.203-.041-.016-.001-.03-.01-.047-.01H7c-.017 0-.03.009-.047.01-.069.003-.133.023-.2.04-.06.016-.121.026-.177.052-.053.025-.097.064-.146.098-.058.042-.116.08-.165.134-.01.012-.026.017-.036.03-.03.035-.04.079-.063.117-.037.06-.074.117-.098.184-.024.067-.032.133-.041.202C6.02 13.913 6 13.953 6 14v32a1 1 0 001 1h46a1 1 0 001-1V14c0-.047-.02-.087-.027-.133-.01-.069-.017-.135-.04-.202zM50.22 15L30 31.703 9.78 15h40.44zM8 45V16.123l21.363 17.648a.996.996 0 001.274 0L52 16.123V45H8z" />
  </svg>
);

export default EmailIcon;
