import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";

const SlideIn = keyframes`
from{
    transform: translateX(100%);
    visibility: hidden;
}
to{
    transform: translateX(0%);
    visibility: visible;
}`;
const SlideOut = keyframes`
0%{
    transform: translateX(0%);
    visibility: visible;
}
25%{
    transform: translateX(-10%);
}
100%{
    transform: translateX(100%);
    visibility: hidden;
}`;
const FadeIn = keyframes`
from{
  opacity: 0;
  visibility: hidden;
}
to{
  opacity: 1;
  visibility: visible;
}`;
const FadeOut = keyframes`
from{
  opacity: 1;
  visibility: visible;
}
to{
  opacity: 0;
  visibility: hidden;
}`;

export const GenreDetailContainerStyled = styled.div`
  position: relative;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0 2rem 1rem;

  background-color: white;

  .table-wrapper {
    width: 100%;
    margin-top: 2rem;
    div:first-child {
      padding: 0;
    }
  }
  .tab_layout {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    position: sticky;
    top: 0;

    padding-top: 2rem;

    background-color: white;
    z-index: 2;

    .title_layout {
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    flex: 1;
    justify-content: center;

    margin-top: 1rem;
  }
  .information-panel {
    position: fixed;
    width: 350px;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    padding: 24px;

    background-color: #f8f8ff;
    z-index: 13;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    visibility: ${({ showSidePanel = false }) =>
      showSidePanel ? "visible" : "hidden"};
    animation: ${({ showSidePanel = false }) =>
        showSidePanel ? SlideIn : SlideOut}
      0.35s ease-in-out;
  }
  .not-found-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: gray;

    span {
      margin-top: 1.5rem;

      text-transform: uppercase;
    }
  }
  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: ${({ showSidePanel = false }) =>
      showSidePanel ? "visible" : "hidden"};

    background-color: rgba(0, 0, 0, 0.5);
    animation: ${({ showSidePanel }) => (showSidePanel ? FadeIn : FadeOut)}
      0.35s ease-in-out;
    z-index: 12;
  }
  .dismiss-button {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .genre-name {
    text-transform: capitalize;
    margin-bottom: 24px;
  }
  .information-section {
    height: 100%;
    display: flex;
    flex-direction: column;

    margin-bottom: 12px;

    overflow: auto;
  }
  .edit-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 4px;
  }
  .creator-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    margin-bottom: 12px;

    input[type="checkbox"] {
      display: none;
    }
  }
  .creator-record {
    display: flex;
    height: 40px;
  }
  .creator-detail {
    width: 100%;
    max-height: 0;
    display: flex;
    flex-direction: column;

    border-radius: 8px;

    overflow: hidden;
    transition: all 0.2s ease-in-out;

    .detail {
      width: 100%;

      padding: 0.5rem;
      margin-top: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
    }
  }
  .chevron-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-in-out;
  }
  input:checked + .creator-detail {
    max-height: 1000px;

    padding: 1rem;
    border: 1px dashed rgba(0, 0, 0, 0.2);
  }
  input:checked + .creator-detail + .creator-record > .chevron-wrapper {
    transform: rotate(90deg);
  }
  .edit-button {
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;

    background-color: #ffcc00;
    color: #000;
    cursor: pointer;
  }
  .avatar {
    width: 36px;
    height: 36px;

    margin-right: 12px;

    background-color: gray;
    border-radius: 36px;
  }
  .display-name {
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .information-panel {
      width: 100%;
    }
  }
`;

export const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
  max-width: 1400px;

  input[type="checkbox"] {
    display: none;
  }
  input:checked + .detail {
    max-height: 4000px;
  }
  .detail {
    display: flex;
    max-height: 0;
    height: 100%;
    width: 300px;
    position: fixed;
    top: 0;
    right: 0;

    background-color: red;
  }
  .genre-wrapper {
    display: flex;
    height: 120px;
    width: calc(25% - 10px);
    position: relative;

    margin: 5px;
  }
  .genre-container {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    border-radius: 8px;

    background-color: white;
    cursor: pointer;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 6px 1rem;

    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    transform: translateY(calc(100% - 24px - 12px));
    transition: all 0.2s ease-in-out;
    color: white;
  }
  .genre-container:hover > .overlay {
    transform: translateY(0%);
  }
  .genre-container:hover > div:first-of-type {
    filter: blur(8px);
    transition: all 0.2s ease-in-out;
  }
  .genre-container:hover > div:first-of-type > img {
    width: 120%;
    transition: all 0.2s ease-in-out;
  }
  .genre-name {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
  }
  .creator-count-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ordering-number {
    position: absolute;
    right: 20px;

    padding: 16px 4px 0;

    background-color: #ffcc00;
    z-index: 1;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .genre-wrapper {
      width: calc(50% - 10px);
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .genre-wrapper {
      width: 100%;
    }
  }
`;

export const ImagePreloadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  position: relative;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .preload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: gray;
    color: white;
  }
`;
