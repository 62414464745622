import React from "react";
import { connect } from "react-redux";
import { AgentContainerStyled } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import {
  ReactTable,
  ButtonCustom,
  SideContainer,
} from "components";
import { Helmet } from "react-helmet";
import AgentDetailContainer from "../AgentDetailContainer";

class AgentContainer extends React.Component {
  state = { data: [], isAddOpen: false, isAdd: true, detailId: null };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_AGENT_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  handdleClickAdd = (e) => {
    this.setState({ isAddOpen: true, isAdd: true });
  };
  handleClose = (e) => {
    this.setState({ isAddOpen: false });
  };

  handdleClickDetail = (e) => {
    this.setState({ detailId: e, isAddOpen: true, isAdd: false });
    // this.props.history.push(ROUTE_PATH.A_AGENT + "/" + e);
  };
  handleAddData = (value) => {
    this.setState([...this.state.data, value]);
  };
  handleChange = (value) => {
    const newData = [...this.state.data];
    const findIndex = newData.findIndex((item) => item.id === value.id);
    newData[findIndex] = value;
    this.setState({ data: newData });
  };

  render() {
    const { data, isAddOpen, isAdd, detailId } = this.state;
    return (
      <AgentContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="tab_layout">
          <div className="title_layout">AR System</div>
          <div className="button_layer">
            <ButtonCustom
              theme_yellow_2
              label="New AR"
              onClick={this.handdleClickAdd}
            />
          </div>
        </div>
        <div className="table-wrapper">
          <ReactTable
            columns={columns({ handdleClickDetail: this.handdleClickDetail })}
            data={data}
          />
        </div>
        <SideContainer
          title={isAdd ? "New AR" : "Edit AR"}
          isShow={isAddOpen}
          handleClose={this.handleClose}
        >
          <AgentDetailContainer
            isAdd={isAdd}
            detailId={detailId}
            handleAddData={this.handleAddData}
            handleClose={this.handleClose}
            handleChange={this.handleChange}
          />
        </SideContainer>
      </AgentContainerStyled>
    );
  }
}

const columns = ({ handdleClickDetail }) => [
  {
    Header: "Displayname",
    accessor: "displayname",
    style: { width: "350px" },
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Detail",
    accessor: "id",
    style: { width: "10px" },
    Cell: (e) => (
      <ButtonCustom
        theme_yellow_2
        label="detail"
        onClick={() => handdleClickDetail(e.value)}
      />
    ),
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentContainer);
