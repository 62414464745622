import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  width: 48px;
  height: 24px;
  position: relative;

  input[type="checkbox"] {
    display: none;
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 24px;

    background-color: #c0c0c0;
    transition: 0.4s;
    cursor: pointer;
  }
  .slider:before {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 4px;

    border-radius: 24px;

    content: "";
    transform: translateY(-50%);
    background-color: white;
    transition: 0.4s;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3), inset 0 0 4px rgba(0, 0, 0, 0.05);
  }
  input:checked + .slider {
    background-color: #10cf6f;
  }
  input:checked + .slider:before {
    transform: translate(20px, -50%);
  }
`;
