import styled from "styled-components";
import // COLOR,
// FONT
"assets";

export const CouponFormStyled = styled.div`
  input[type="text"] {
    width: 100%;
    height: 35px;

    border-radius: 4px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 8px;
  }
  .theme_standard_2 {
    input {
      width: 100%;
    }
  }
  .group_form {
    .line_input {
      display: flex;
      margin-bottom: 10px;
      &.no_margin_bottom {
        margin-bottom: 0px;
      }
      .left_layout {
        width: 100px;
        margin-right: 5px;
      }
      .right_layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        &.text_right {
          justify-content: flex-end;
        }
        .obj_1 {
          width: 35%;
        }
        .obj_2 {
          width: 44%;
        }
        .button_layout {
          margin-left: 5px;
        }
      }
    }
  }
`;
