import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { OrderFormStyled } from './styled';
import { VALIDATE } from 'helpers';
// import { ROUTE_PATH } from 'helpers';
import {
  InputRadio,
  InputTextArea2,
  InputCheckbox,
  InputLine,
  InputDropdownLine,
} from 'components';

class OrderForm extends React.Component {
  state = {
    radioType: 'OTHER',
  };

  handleRadio = (value) => {
    this.setState({ radioType: value });
  };

  // handleClickPrivacy = () => {
  //   this.props.history.push(ROUTE_PATH.PRIVACY);
  // };
  render() {
    const {
      categoryOptions,
      handleClickRequest,
      dataCreator,
      dataCoupon,
      handleSubmit,
      disabledSubmit,
      handleClickUseCoupon,
      serviceSelected
    } = this.props;
    const selectOptions = [
      {
        label: 'ให้ผู้อื่น',
        value: 'OTHER',
      },
      {
        label: 'ให้ตัวเอง',
        value: 'ME',
      },
    ];

    return (
      <OrderFormStyled>
        <form onSubmit={handleSubmit(handleClickRequest)}>
          <div className="title">{`สั่งซื้อวิดีโอนี้จาก ${
            dataCreator ? dataCreator.displayname : ''
          }`}</div>
          <div className="text_1">วิดีโอนี้ซื้อให้</div>
          <div className="input_wrap">
            <Field
              theme_standard
              name="giftee_type"
              options={selectOptions}
              component={InputRadio}
              onChange={this.handleRadio}
              radioType={this.state.radioType}
              checked
            />
          </div>
          {this.state.radioType === 'OTHER' ? (
            <div className="row">
              <Field
                theme_standard
                name="gifter_name"
                label="จาก"
                component={InputLine}
              />
              <Field
                theme_standard
                label="ชื่อผู้รับ"
                name="giftee_name"
                component={InputLine}
              />
            </div>
          ) : (
            <div className="row">
              <Field
                theme_standard
                name="giftee_name"
                label="ชื่อผู้รับ"
                component={InputLine}
                color="black"
              />
            </div>
          )}
          <div className="text_1">วัตถุประสงค์</div>
          <div className="input_wrap dropdown">
            <Field
              theme_standard
              name="category_id"
              component={InputDropdownLine}
              validate={VALIDATE.REQUIRE}
            >
              {/* {categoryOptions &&
                categoryOptions.map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.name_th}
                  </option>
                ))} */}
              {categoryOptions &&
                categoryOptions.map((e, i) => (
                  <optgroup key={i} label={`${e.name_th}`}>
                    {e.categories &&
                      e.categories.map((e1, i1) => (
                        <option key={i1} value={e1.id}>
                          {e1.name_th}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </Field>
          </div>
          <div className="text_1">Brief งาน</div>
          <div className="input_wrap">
            {this.state.radioType === 'OTHER' ? (
              <Field
                theme_standard
                name="brief"
                placeholder={`เพื่อนหนูชื่อ มาณี (มานี, ผู้หญิง) คือว่า กำลังจะถึงวันเกิดนาง เลยอยากจะฝาก พี่${
                  dataCreator ? dataCreator.displayname : ''
                } ช่วยแฮปปี้เบิร์ดเดย์นางหน่อยค่า อวยพรนางหน่อย แล้วฝากบอกท้ายคลิปทีค่าว่า มานะ(มา-นะ) หวังว่าจะถูกใจ อิอิ`}
                component={InputTextArea2}
                validate={VALIDATE.REQUIRE}
              />
            ) : (
              <Field
                theme_standard
                name="brief"
                placeholder={`คือว่าหนูชื่อ มานะ นะคะ (มา-นะ) คือหนูพึ่งโดนเท มา พี่ ${
                  dataCreator ? dataCreator.displayname : ''
                } ปลอบหนูหน่อย อะไรก็ได้คือไม่ไหวแล้ว (หนูอยู่ด้อมพี่มานานมาก T…T)`}
                component={InputTextArea2}
                validate={VALIDATE.REQUIRE}
              />
            )}
          </div>
          <div className="title">ข้อมูลการติดต่อ</div>
          <div>โปรดกรอกเพื่อข้อมูลการติดต่อ ใบเสร็จ และการอัพเดทต่างๆ</div>
          <div className="row " style={{ marginTop: '10px' }}>
            <Field
              theme_standard
              // disabled
              name="contact_email"
              label="อีเมลล์"
              component={InputLine}
              validate={VALIDATE.REQUIRE}
            />
            <Field
              theme_standard
              name="contact_phone"
              label="เบอร์โทรศัพท์"
              component={InputLine}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="row">
            <Field
              theme_standard
              inputWidth="95%"
              name="coupon"
              label="ใช้โค๊ดส่วนลด"
              component={InputLine}
            />
            <div className="buttun_coupon_wrap">
              <button
                className="button_coupon"
                type="button"
                onClick={handleClickUseCoupon}
              >
                ใช้ส่วนลด
              </button>
            </div>
          </div>
          <div className="input_wrap check_approve">
            <Field
              theme_standard
              name="is_private"
              label={`อนุญาติให้ ${
                dataCreator ? dataCreator.displayname : ''
              } โชว์วีดีโอคุณบนโปรไฟล์`}
              component={InputCheckbox}
            />
          </div>
          {serviceSelected && (
            <div className="button_layout">
              {!dataCoupon ? (
                <button
                  className={`button_wrap ${disabledSubmit ? 'disabled' : ''}`}
                  disabled={disabledSubmit}
                >{`ค่าใช้จ่าย ฿${serviceSelected.rate_coins.toLocaleString()}`}</button>
              ) : (
                <button className="button_wrap">
                  ค่าใช้จ่าย ลดจาก{' '}
                  <span className="text_cut">
                    ฿{serviceSelected.rate_coins.toLocaleString()}{' '}
                  </span>{' '}
                  เป็น{' '}
                  <span>
                    ฿{dataCoupon.pre_calc.amount_coins.toLocaleString()}
                  </span>
                </button>
              )}
            </div>
          )}
          <h5>
            By signing up, you agree to meWOW's{' '}
            <a href="/page/terms">Term of Service</a> and{' '}
            <a href="/page/privacy">Privacy Policy</a>
          </h5>
        </form>
      </OrderFormStyled>
    );
  }
}

export default reduxForm({
  form: 'OrderForm', // a unique identifier for this form
  enableReinitialize: true,
})(OrderForm);
