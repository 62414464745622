import React from "react";

const RefundIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 -5 100 100"
    height={size}
    width={size}
    fill={fill}
  >
    <path
      d="M84 28v35a2 2 0 01-2 2H26.524l1.756 1.464a2 2 0 11-2.56 3.072l-6-5a2 2 0 010-3.072l6-5a2 2 0 112.56 3.072L26.524 61H80V30H16v27a2 2 0 01-4 0V28a2 2 0 012-2h68a2 2 0 012 2zM46 52v-5a6.007 6.007 0 01-6-6v-1a6.007 6.007 0 016-6 2 2 0 014 0 6.007 6.007 0 016 6 2 2 0 01-4 0 2.002 2.002 0 00-2-2v5a6.007 6.007 0 016 6v1a6.007 6.007 0 01-6 6 2 2 0 01-4 0 6.007 6.007 0 01-6-6 2 2 0 014 0 2.003 2.003 0 002 2zm4 0a2.003 2.003 0 002-2v-1a2.002 2.002 0 00-2-2zm-4-9v-5a2.002 2.002 0 00-2 2v1a2.002 2.002 0 002 2z"
      data-name="Outline ComPath"
    />
  </svg>
);

export default RefundIcon;
