import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
//  } from 'assets'

export const InputSearchNameStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    width: 100%;
    position: relative;
    .input {
      padding: 9px 31px 9px 9px;
      border-radius: 15px;
      border: 1px solid #d9d9d9;
      outline: none;
    }
    .icon {
      width: 20px;
      position: absolute;
      top: 7px;
      right: 10px;
      cursor: pointer;
    }
  }

  .theme_mobile_standard {
    width: 100%;
    position: relative;
    .input {
      width : 100%;
      padding: 9px 31px 9px 9px;
      border-radius: 15px;
      border: 1px solid #d9d9d9;
      outline: none;
    }
    .icon {
      width: 20px;
      position: absolute;
      top: 7px;
      right: 10px;
      cursor: pointer;
    }
  }
`;
