import styled from "styled-components";
import { FONT, SCREEN } from "assets";

export const NavbarStyled = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;

  padding-left: 60px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 9;

  input[type="checkbox"] {
    display: none;
  }
  .hamburger-menu {
    position: absolute;
    top: 50%;
    left: 60px;
    width: 30px;
    height: 30px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;

    transform: translateY(-50%);
  }
  .line {
    width: 100%;
    height: 3px;

    border-radius: 10px;

    background-color: rgba(0, 0, 0, 0.5);
  }
  .txt_title {
    width: 90%;

    font-weight: 700;
    font-size: ${FONT.SIZE_12};
    color: #fbc210;
    text-shadow: 2px 2px 2px #24478f;
    text-align: center;
  }
  .avatar {
    position: relative;

    margin: 5px 20px 0 0;
    cursor: pointer;
  }
  .person {
    height: 42px;
    width: 42px;

    border-radius: 50%;
    border: 2px solid transparent;

    transition: all 0.2s ease-in-out;

    :hover {
      border-color: #ffcc00;
    }
  }
  .profile-menu-container {
    width: 100%;
    max-height: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 100%;

    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
  input:checked + .profile-menu-container {
    max-height: 4000px;
    width: 200px;

    padding: 12px 0;
    z-index: 1;
  }
  .menu {
    height: 50px;
    display: flex;
    align-items: center;

    padding: 12px;

    transition: all 0.3s ease-in-out;

    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .txt_title {
      width: 90%;
      font-weight: 700;
      font-size: ${FONT.SIZE_5};
      margin-right: 10px;
      color: #fbc210;
      text-shadow: 2px 2px 2px #24478f;
      text-align: end;
    }
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .hamburger-menu {
      left: 20px;
      display: flex;
    }
    .txt_title {
      font-size: ${FONT.SIZE_5};
      margin-right: 10px;
      text-align: end;
    }
  }
`;

export const Dismiss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 0;
  cursor: default;
`;
