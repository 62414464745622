import styled from "styled-components";
import { COLOR, SCREEN } from "assets";

export const CouponItemStyle = styled.div`
  opacity: ${(props) => (props.is_active === 1 ? 1 : 0.5)};
  position: relative;
  max-width: 280px;
  width: 100%;
  height: auto;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 10px;
  align-items: space-around;
  cursor: pointer;
  .code {
    max-width: 220px;
    font-size: 25px;
    padding: 15px 0px;
  }
  .expire {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    word-wrap: break-word;
    justify-content : center;
    /* max-width: 230px; */
  }
  .ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: ${(props) =>
    props.type === "SINGLE"
      ? "5px solid #2980b9"
      : `5px solid ${COLOR.YELLOW_1}`};
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 160px;
    padding: 5px 0;
    background-color: ${(props) =>
    props.type === "SINGLE" ? "#3498db" : COLOR.YELLOW_1};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 600 15px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
`;

export const CouponContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 100%;

  background-color: white;

  .tab_layout {
    align-items: center;
    display: flex;
    height: 60px;
    padding-top: 1rem;
  }

  .title_layout {
    font-weight: bold;
  }
  .form_layout {
    padding: 1rem;
    .form_layout {
      margin-top: 10px;
      padding: 0px;
    }
  }
  .button_coupon {
    border-radius: 4px;
    padding: 6px 20px;
    font-size: 16px;
    background: #0059b3;
    color: white;
    font-weight: bold;
    border: 1px solid #0032b3;
    outline: none;
    margin-left: 20px;
  }
  .coupon_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media only screen and (max-width: ${SCREEN.TABLET}) {
      justify-content: center;
    }
  }

  .search_container {
    padding-left: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 80%;
    @media only screen and (max-width: ${SCREEN.PHONE}) {
      padding-top: 2rem;
    }
  }
`;
