import React from "react";
import { connect } from "react-redux";
import { GenreDetailContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
// import { adminService } from 'apiService';
// import { toast } from 'react-toastify';
import { ROUTE_PATH } from "helpers";
// import { GenreForm } from 'forms/AdminFormSystem';
import {
  GenreProfileContainer,
  GenreUploadContainer,
} from "../SectionsGenreDetail";

class GenreDetailContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    if (!this.props.isAdd) {
      this.setState({
        slugId: this.props.match.params.id,
      });
    }
  };

  callback = (e) => {
    this.props.history.push(ROUTE_PATH.A_GENRE + "/" + e);
    this.setState({
      slugId: e,
    });
  };

  render() {
    const { slugId } = this.state;
    return (
      <GenreDetailContainerStyled>
        <div className="form-wrapper">
          <div className="form_item_layout">
            <GenreProfileContainer
              slugId={
                this.props.isAdd
                  ? undefined
                  : this.props.match.params.id
              }
              callback={this.callback}
              isAdd={this.props.isAdd}
              handleAddSuccess={this.props.handleAddSuccess}
            />
          </div>
          {slugId && (
            <>
              <div className="form_item_layout last">
                <GenreUploadContainer slugId={slugId} />
              </div>
            </>
          )}
        </div>
      </GenreDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenreDetailContainer);
