import styled from "styled-components";

export const Container = styled.div`
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    margin: 2rem 0;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;

      margin: 4px;

      border-radius: 30px;

      cursor: pointer;
      background-color: white;
      color: black;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        outline: none;
      }
    }
    .active {
      background-color: gold;
    }
  }
`;
