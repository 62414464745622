import React from "react";

const ChevronRightIcon = ({ fill = "black", size = 40 }) => (
  <svg viewBox="0 0 100 100" height={size} width={size} fill={fill}>
    <path
      d="M38.232 68.232a2.5 2.5 0 003.536 3.536l20-20a2.5 2.5 0 000-3.536l-20-20a2.5 2.5 0 00-3.536 3.536L56.464 50 38.232 68.232z"
      fillRule="evenodd"
    />
  </svg>
);

export default ChevronRightIcon;
