import React from "react";
import { connect } from "react-redux";
import { AccountingContainerStyled } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { Helmet } from "react-helmet";
import { ReactTable, ReactTableExpand, NotFoundIcon } from "components";
import moment from "moment";

class AccountingContainer extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_REDEEMSLIST();
    if (res && res.success) {
      this.setState({
        data: res.data.map((e, i) => ({ ...e })),
      });
    }
  };

  render() {
    const { data } = this.state;
    const showTable = data.length > 0;
    return (
      <AccountingContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="tab_layout">
          <div className="title_layout">Accounting System</div>
        </div>
        <div className="table-wrapper">
          {showTable && (
            <ReactTableExpand
              columns={columns()}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
          {!showTable && (
            <div className="no-data-wrapper">
              <div className="no-data">
                <NotFoundIcon size={100} />
                <span>No Information</span>
              </div>
            </div>
          )}
        </div>
      </AccountingContainerStyled>
    );
  }
}

const columns = () => [
  {
    // Make an expander cell
    Header: () => null, // No header
    id: "expander", // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? "👇" : "👉"}
      </span>
    ),
  },
  {
    Header: "Creator Id",
    accessor: "creator.id",
    style: { width: "70px" },
  },
  {
    Header: "Display Name",
    accessor: "creator.displayname",
    style: { width: "100px" },
  },
  {
    Header: "Redeemed Coins",
    accessor: "redeemed_coins",
    style: { width: "100px" },
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "right" }}>
        {Number(original.redeemed_coins).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    ),
  },
  {
    Header: "Date",
    accessor: "updated_at",
    style: { width: "150px", textAlign: "center" },
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "center" }}>
        {moment(original.updated_at).format("DD/MM/YYYY")}
      </div>
    ),
  },
];

const columns2 = () => [
  {
    Header: "Order ID",
    accessor: "order.id",
  },
  {
    Header: "Gifter Name",
    accessor: "order.gifter_name",
  },
  {
    Header: "Completed At",
    accessor: "order.updated_at",
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "center" }}>
        {moment(original.updated_at).format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    Header: "Full Amount",
    accessor: "rate_coins",
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "right" }}>
        {Number(original.rate_coins).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    ),
  },
  {
    Header: "Discount",
    accessor: "discount_coins",
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "right" }}>
        {Number(original.discount_coins).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    ),
  },
  {
    Header: "Redeemable",
    accessor: "redeemable_coins",
    Cell: ({ row: { index, original } }) => (
      <div style={{ textAlign: "right" }}>
        {Number(original.redeemable_coins).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </div>
    ),
  },
];

const renderRowSubComponent = ({ row }) => (
  <pre
    style={{
      fontSize: "10px",
    }}
  >
    <ReactTable
      notUsePagi
      columns={columns2({})}
      data={row.original.invoices}
    />
  </pre>
);

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingContainer);
