import React from "react";
import { connect } from "react-redux";
import { EnrollContainerStyled } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { Helmet } from "react-helmet";
import { ReactTable } from "components";

class EnrollContainer extends React.Component {
  state = { data: [] };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_ENROLL_CREATOR_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <EnrollContainerStyled>
        <Helmet>
          <title>admin</title>
        </Helmet>
        <div className="tab_layout">
          <div className="title_layout">Enroll</div>
        </div>
        <div className="table-wrapper">
          <ReactTable
            columns={columns({ handdleClickDetail: this.handdleClickDetail })}
            data={data}
          />
        </div>
      </EnrollContainerStyled>
    );
  }
}

const columns = ({ handdleClickDetail }) => [
  {
    Header: "Name",
    accessor: "name",
    // style: { width: '350px' },
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone_no",
    style: { width: "80px" },
  },
  {
    Header: "Line Id",
    accessor: "line_id",
  },
  {
    Header: "Socialmedia",
    accessor: "socialmedia",
  },
  {
    Header: "Socialmedia Id",
    accessor: "socialmedia_id",
  },
  {
    Header: "Follower No",
    accessor: "follower_no",
    style: { width: "90px" },
  },
];

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollContainer);
