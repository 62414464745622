import React from 'react'
import { StyledCard, Title } from './styled'

export const Card = ({ children, title, headerBg, headerColor, theme_yellow, style }) => {

  return (
    <StyledCard >
      <div className="card_container" style={style}>
        <Title headerBg={headerBg} headerColor={headerColor}>
          {title}
        </Title >
        <div className="card_content">
          {children}
        </div>
      </div>
    </StyledCard>
  )
}