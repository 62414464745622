import React from "react";
import { WheelDetailStyled } from "./styled";
import { SwitchButton, ButtonCustom } from "components";

const WheelDetail = ({ wheelData, onSubmit, onCancel, isShow }) => {
  const { id, is_active, order, slug, title } = wheelData;

  return (
    <WheelDetailStyled onSubmit={(event) => onSubmit(event, slug)}>
      <div className="section">
        <span className="title">Wheel name</span>
        <input
          key={id?.toString()}
          type="text"
          defaultValue={title || ""}
          name="title"
        />
      </div>
      <div className="section">
        <span className="title">Order</span>
        <input
          key={id?.toString()}
          type="number"
          defaultValue={order || ""}
          name="order"
        />
      </div>
      <div className="section">
        <span className="title">Slug</span>
        <input
          key={id?.toString()}
          type="text"
          defaultValue={slug || ""}
          name="slug"
        />
      </div>
      <div className="section">
        <span className="title">Active</span>
        {isShow && (
          <SwitchButton
            key={id?.toString()}
            defaultChecked={is_active}
            name="is_active"
          />
        )}
      </div>
      <div className="button-wrapper section">
        <ButtonCustom theme_yellow_2 label="Submit" />
        <ButtonCustom
          theme_delete
          label="Cancel"
          onClick={(event) => {
            event.preventDefault();
            onCancel();
          }}
        />
      </div>
    </WheelDetailStyled>
  );
};

export default WheelDetail;
