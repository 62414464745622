import styled, { keyframes } from "styled-components";
import { SCREEN } from "assets";

const SlideIn = keyframes`
from{
    transform: translateX(100%);
    visibility: hidden;
}
to{
    transform: translateX(0%);
    visibility: visible;
}`;
const SlideOut = keyframes`
0%{
    transform: translateX(0%);
    visibility: visible;
}
25%{
    transform: translateX(-10%);
}
100%{
    transform: translateX(100%);
    visibility: hidden;
}`;
const FadeIn = keyframes`
from{
  opacity: 0;
  visibility: hidden;
}
to{
  opacity: 1;
  visibility: visible;
}`;
const FadeOut = keyframes`
from{
  opacity: 1;
  visibility: visible;
}
to{
  opacity: 0;
  visibility: hidden;
}`;

export const SideContainerStyled = styled.div`
  .side-bar-container {
    position: fixed;
    width: 40%;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    padding: 45px 24px 24px;

    background-color: #fff;
    z-index: 13;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
    visibility: ${({ isShow = false }) => (isShow ? "visible" : "hidden")};
    animation: ${({ isShow = false }) => (isShow ? SlideIn : SlideOut)} 0.35s
      ease-in-out;
    overflow: auto;
  }
  .side-bar-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    visibility: ${({ isShow = false }) => (isShow ? "visible" : "hidden")};

    background-color: rgba(0, 0, 0, 0.5);
    animation: ${({ isShow }) => (isShow ? FadeIn : FadeOut)} 0.35s ease-in-out;
    z-index: 12;
  }
  .sidebar-dismiss-button {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .sidebar-title {
    margin-bottom: 1rem;

    font-size: 20px;
  }
  @media (max-width: ${SCREEN.TABLET}) {
    .side-bar-container {
      width: 60%;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .side-bar-container {
      width: 100%;
    }
  }
`;
