import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { OrderContainerStyled, CardTable, PopOver } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  InputSearchName,
  CalendarIcon,
  TelephoneIcon,
  EmailIcon,
  SwitchButton,
  EditIcon,
  Pagination,
  CrossIcon,
} from "components";
import moment from "moment";

const OrderContainer = (props) => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("ALL");
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [updatedBrief, setUpdatedBrief] = useState("");
  const [textareaFocus, setTextareaFocus] = useState(false);
  const [currentPageData, setCurrentPageData] = useState(1);
  const [pageList, setPageList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [showPopOver, setShowPopOver] = useState(false);

  const searchRef = useRef();
  const textareaRef = useRef();

  const fetchData = async (offset = 0) => {
    const {
      success,
      data: orderData,
      page,
    } = await adminService.GET_ORDER_LIST("ALL", offset);
    if (success) {
      const { current_page, last_page } = page;

      setData(orderData);
      setCurrentPageData(current_page - 1);
      setPageList(page);
      setTotalPages(last_page);
    }
  };

  const handdleClickTab = (menuName, offset = 0) => async (event) => {
    if (event) {
      event.preventDefault();
    }
    const {
      success,
      page,
      data: orderData,
    } = await adminService.GET_ORDER_LIST(menuName, offset);
    setSearchInput("");
    setSearchData([]);
    if (success) {
      const { current_page, last_page } = page;
      setData(orderData);
      setCurrentPageData(current_page - 1);
      setPageList(page);
      setTotalPages(last_page);
    }
    setActiveTab(menuName);
  };

  const handdleClickCancle = (e) => {
    confirmAlert({
      title: "Confirm to cancle",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => cancleOrder(e.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const cancleOrder = async (e) => {
    const params = {
      status: "REJECTED",
    };

    const { success } = await adminService.PATCH_ORDER_UPDATE(params, e);
    if (success) {
      toast.success("ยกเลิกสำเร็จ");
      handdleClickTab(activeTab);
    } else {
      toast.error("ยกเลิกไม่สำเร็จ");
    }
  };

  const updateOrderIsPrivate = async (e, id) => {
    const params = {
      is_private: e,
    };

    const { success } = await adminService.PATCH_ORDER_UPDATE(params, id);
    if (success) {
      const cloneData = [...data];
      const newData = cloneData.map((value) => {
        if (value.id === id) {
          value.is_private = !value.is_private;
          return value;
        }
        return value;
      });
      setData(newData);
      toast.success("บันทึกสำเร็จ");
      handdleClickTab(activeTab);
    } else {
      toast.error("บันทึกไม่สำเร็จ");
    }
  };

  const onEditBrief = ({ target: { value: text } }) => setUpdatedBrief(text);

  const onCancelEditBrief = () => {
    setUpdatedBrief(selectedOrder.brief);
    setTextareaFocus(false);
    textareaRef.current.value = selectedOrder.brief;
  };

  const onSubmitUpdatedBrief = async () => {
    const params = {
      brief: updatedBrief,
    };

    const { success } = await adminService.PATCH_ORDER_UPDATE(
      params,
      selectedOrder.id
    );
    if (success) {
      toast.success("บันทึกสำเร็จ");
      handdleClickTab(activeTab);
      setTextareaFocus(false);
    } else {
      toast.error("บันทึกไม่สำเร็จ");
    }
  };

  const onSelectOrder = (value) => () => {
    if (textareaRef?.current?.value) {
      textareaRef.current.value = value.brief;
    }
    setSelectedOrder(value);
    setShowPopOver(true);
  };

  const handlePageClick = (e) => {
    handdleClickTab(activeTab, e.selected * pageList?.per_page)();
  };

  const handleSearch = (e) => {
    const newData = [...data];
    setSearchInput(e);
    setSearchData(
      newData.filter((item) => {
        return (
          item.creator.displayname.toUpperCase().includes(e.toUpperCase()) ||
          item.id.toString().includes(e)
        );
      })
    );
  };

  const getFilterData = () => {
    if (searchInput !== "") {
      return searchData.length === 0 ? [] : searchData;
    } else {
      return data;
    }
  };

  const onToggleSwitch = (checked, id) => {
    confirmAlert({
      title: "Confirm to toggle",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => updateOrderIsPrivate(checked, id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const closePopOver = () => {
    setTextareaFocus(false);
    setShowPopOver(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <OrderContainerStyled showSidePanel={true}>
      <Helmet>
        <title>admin</title>
      </Helmet>

      <span className="page-header">Order System</span>
      <div className="search-wrapper">
        <InputSearchName
          theme_mobile_standard
          placeHolder="Search Order/Creator"
          handleSearch={handleSearch}
          useRef={searchRef}
        />
      </div>
      <div className="menu-wrapper">
        <button
          type="button"
          className={`menu ${activeTab === "ALL" && "active"}`}
          onClick={handdleClickTab("ALL")}
        >
          ทั้งหมด
        </button>
        <button
          type="button"
          className={`menu ${activeTab === "PENDING" && "active"}`}
          onClick={handdleClickTab("PENDING")}
        >
          ดำเนินการ
        </button>
        <button
          type="button"
          className={`menu ${activeTab === "COMPLETED" && "active"}`}
          onClick={handdleClickTab("COMPLETED")}
        >
          เสร็จสิ้นแล้ว
        </button>
        <button
          type="button"
          className={`menu ${activeTab === "NEW" && "active"}`}
          onClick={handdleClickTab("NEW")}
        >
          ยังไม่ชำระ
        </button>
      </div>

      <div className="tab_layout">
        <div className="searchResult">
          <p>
            {searchInput !== ""
              ? `Search : ${activeTab} , "${searchInput}" ${searchData.length} รายการ`
              : null}
          </p>
        </div>
      </div>

      <div className="content">
        <CardTable>
          {getFilterData().map((value, index) => (
            <div
              className="card-container"
              key={index}
              onClick={onSelectOrder(value)}
            >
              <div className="card-header">
                <div className="status-wrapper section">
                  <span>Order no. {value.id}</span>
                  <div className={"status " + value.status.toLowerCase()}>
                    <span>{value.status.toLowerCase()}</span>
                  </div>
                </div>
                <div className="gifter-wrapper section">
                  <div className="avatar">
                    <span>{value.gifter.displayname[0]}</span>
                  </div>
                  <span className="gifter-name">
                    {value.gifter.displayname}
                  </span>
                </div>
                <div className="contact-wrapper section">
                  <div className="phone-icon-wrapper">
                    <TelephoneIcon size={25} fill="gray" />
                  </div>
                  <span className="phone-number">
                    {value.contact_phone || "-"}
                  </span>
                </div>
                <div className="contact-wrapper section">
                  <div className="email-icon-wrapper">
                    <EmailIcon size={30} fill="gray" />
                  </div>
                  <span className="email">{value.contact_email || "-"}</span>
                </div>
                <div className="line" />
              </div>
              <div className="card-body">
                <div className="creator-wrapper section">
                  <span className="creator">Artist</span>
                  <span className="creator-name">
                    {value.creator.displayname}
                  </span>
                </div>
                {value?.service?.name && (
                  <div className="service-wrapper section">
                    <span className="service">Service</span>
                    <span className="service-name">{value.service.name}</span>
                  </div>
                )}
                <div className="catagory-wrapper section">
                  <span className="catagory">Catagory</span>
                  <span className="catagory-name">
                    {value.category.name_th}
                  </span>
                </div>
                <div className="private-wrapper section">
                  <span className="private-action">Private</span>
                  <SwitchButton
                    checked={value.is_private ? true : false}
                    onChange={(event) =>
                      onToggleSwitch(event.target.checked, value.id)
                    }
                    onClick={(event) => event.stopPropagation()}
                  />
                </div>
                <div className="date section">
                  <div className="calendar-icon-wrapper">
                    <CalendarIcon fill="gray" size={30} />
                  </div>
                  {
                    <span>
                      Created date:{" "}
                      {moment(value.created_at).format("DD/MM/YYYY")} (
                      {moment(value.created_at).fromNow()})
                    </span>
                  }
                </div>
                {value.deadline_date && (
                  <div className="date section">
                    <div className="calendar-icon-wrapper">
                      <CalendarIcon fill="gray" size={30} />
                    </div>
                    <span>
                      Due date: {value.deadline_date}
                      <span className="due-date">
                        ({moment().to(value.deadline_date)})
                      </span>
                    </span>
                  </div>
                )}
                <div className="price-wrapper">
                  <div
                    className={
                      "price tag " +
                      (parseFloat(value.invoice.discount_coins) > 0 &&
                        "discount-number")
                    }
                  >
                    <span>
                      ฿ {parseFloat(value.invoice.rate_coins).toLocaleString()}
                    </span>
                  </div>
                  {parseFloat(value.invoice.discount_coins) > 0 && (
                    <div className="price tag">
                      <span className="number">
                        ฿{" "}
                        {parseFloat(
                          value.invoice.amount_coins
                        ).toLocaleString()}
                      </span>
                    </div>
                  )}
                </div>
                <div className="brief-wrapper section">
                  <span>Brief</span>
                  <div className="brief-detail">
                    <span>{value.brief}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CardTable>

        <Pagination
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPageData}
        />
      </div>

      <PopOver show={showPopOver}>
        {selectedOrder && (
          <React.Fragment>
            <div className="container-wrapper">
              <div className="close" onClick={closePopOver}>
                <CrossIcon fill="rgba(0,0,0,0.5)" size={25} />
              </div>
              <div className="container">
                <div className="card-header">
                  <div className="status-wrapper section">
                    <span>Order no. {selectedOrder.id}</span>
                    <div
                      className={
                        "status " + selectedOrder?.status?.toLowerCase()
                      }
                    >
                      <span>{selectedOrder?.status?.toLowerCase()}</span>
                    </div>
                  </div>
                  <div className="gifter-wrapper section">
                    <div className="avatar">
                      <span>{selectedOrder.gifter.displayname[0]}</span>
                    </div>
                    <span className="gifter-name">
                      {selectedOrder.gifter.displayname}
                    </span>
                  </div>
                  <div className="contact-wrapper section">
                    <div className="phone-icon-wrapper">
                      <TelephoneIcon size={25} fill="gray" />
                    </div>
                    <span className="phone-number">
                      {selectedOrder.contact_phone || "-"}
                    </span>
                  </div>
                  <div className="contact-wrapper section">
                    <div className="email-icon-wrapper">
                      <EmailIcon size={30} fill="gray" />
                    </div>
                    <span className="email">
                      {selectedOrder.contact_email || "-"}
                    </span>
                  </div>
                  <div className="line" />
                </div>
                <div className="card-body">
                  <div className="creator-wrapper section">
                    <span className="creator">Artist</span>
                    <span className="creator-name">
                      {selectedOrder.creator.displayname}
                    </span>
                  </div>
                  <div className="service-wrapper section">
                    <span className="service">Service</span>
                    <span className="service-name">
                      {selectedOrder.service.name}
                    </span>
                  </div>
                  <div className="catagory-wrapper section">
                    <span className="catagory">Catagory</span>
                    <span className="catagory-name">
                      {selectedOrder.category.name_th}
                    </span>
                  </div>

                  <div className="price-wrapper-popover section">
                    <span>Price</span>
                    <div className="price-section">
                      <div
                        className={
                          "price tag " +
                          (parseFloat(selectedOrder.invoice.discount_coins) >
                            0 && "discount-number")
                        }
                      >
                        <span>
                          ฿{" "}
                          {parseFloat(
                            selectedOrder.invoice.rate_coins
                          ).toLocaleString()}
                        </span>
                      </div>
                      {parseFloat(selectedOrder.invoice.discount_coins) > 0 && (
                        <div className="price tag">
                          <span className="number">
                            ฿{" "}
                            {parseFloat(
                              selectedOrder.invoice.amount_coins
                            ).toLocaleString()}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedOrder.invoice?.receipt?.method && (
                    <div className="payment-wrapper section">
                      <span>Payment </span>
                      <span>{selectedOrder.invoice?.receipt?.method}</span>
                    </div>
                  )}
                  <div className="date section">
                    <div className="calendar-icon-wrapper">
                      <CalendarIcon fill="gray" size={30} />
                    </div>
                    {
                      <span>
                        Created date:{" "}
                        {moment(selectedOrder.created_at).format("DD/MM/YYYY")}{" "}
                        ({moment(selectedOrder.created_at).fromNow()})
                      </span>
                    }
                  </div>
                  {selectedOrder.deadline_date && (
                    <div className="date section">
                      <div className="calendar-icon-wrapper">
                        <CalendarIcon fill="gray" size={30} />
                      </div>
                      <span>
                        Due date: {selectedOrder.deadline_date} (
                        {moment().to(selectedOrder.deadline_date, true)})
                      </span>
                    </div>
                  )}
                  <div className="line" />
                  {showPopOver && (
                    <div className="private-wrapper section">
                      <span className="private-action">Private</span>
                      <SwitchButton
                        checked={selectedOrder.is_private ? true : false}
                        onChange={(event) =>
                          onToggleSwitch(event.target.checked, selectedOrder.id)
                        }
                      />
                    </div>
                  )}

                  <div className="brief-wrapper section">
                    <span>
                      Brief
                      <div
                        className="edit-icon-wrapper"
                        onClick={() => setTextareaFocus(!textareaFocus)}
                      >
                        <EditIcon size={25} fill="gray" />
                      </div>
                    </span>
                    <textarea
                      ref={textareaRef}
                      className="textarea-brief-detail"
                      onChange={onEditBrief}
                      disabled={!textareaFocus}
                      defaultValue={selectedOrder.brief}
                    />
                    <div className="text-area-action">
                      {textareaFocus && (
                        <>
                          <button
                            className="cancel-button"
                            onClick={onCancelEditBrief}
                          >
                            Cancel
                          </button>
                          <button
                            className="submit-button"
                            onClick={onSubmitUpdatedBrief}
                          >
                            Submit
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {(selectedOrder.status === "REQUESTED" ||
                    selectedOrder.status === "PENDING") && (
                    <div className="abort-wrapper">
                      <div className="line" />

                      <div className="abort section">
                        <span>ยกเลิกการทำรายการ</span>
                        <button
                          onClick={() => handdleClickCancle(selectedOrder)}
                          className="abort-button"
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="opacity" onClick={closePopOver} />
          </React.Fragment>
        )}
      </PopOver>
    </OrderContainerStyled>
  );
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AdminOrderForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
