export const ROUTE_PATH = {
  HOME: '/',

  LOGIN: '/login',

  A_CREATOR: '/admin/creators',
  A_CREATOR_DETAIL: '/admin/creators/:id',
  A_CATEGORY: '/admin/category',
  A_CATEGORY_DETAIL: '/admin/category/:id',
  A_GENRE: '/admin/genres',
  A_GENRE_DETAIL: '/admin/genres/:id',
  A_REFUND: '/admin/refund',
  A_REFUND_DETAIL: '/admin/refund/:id',
  A_ACCOUNTING: '/admin/accounting',
  A_ACCOUNTING_DETAIL: '/admin/accounting/:id',
  A_ENROLL: '/admin/enroll',
  A_ORDER: '/admin/order',
  A_COUPON: '/admin/coupon',
  A_VOTE: '/admin/vote',
  A_AGENCY: '/admin/agencys',
  A_AGENCY_DETAIL: '/admin/agencys/:id',
  A_AGENT: '/admin/agents',
  A_AGENT_DETAIL: '/admin/agents/:id',
  A_WHEEL: '/admin/wheel',
};

export const VALIDATE = {
  REQUIRE: (value) =>
    value || typeof value === 'number' ? undefined : 'Required',
  NUMBER: (value) =>
    value
      ? isNaN(Number(value))
        ? 'Must be a number'
        : undefined
      : 'Must be a number',
  NUMBER_ONLY: (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,
  EMAIL: (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'email invalid'
      : undefined,
  MIN_LENGTH: (value) =>
    value
      ? value.length < 6
        ? `Must be 6 characters or more`
        : undefined
      : `Must be 6 characters or more`,
  MAX_LENGTH: (value) =>
    value
      ? value.length > 191
        ? `The characters must be less than 191 characters.`
        : undefined
      : `The characters must be less than 191 characters.`,
  MIN_PW_LENGTH: (value) =>
    value
      ? value.length < 8
        ? `Must be 8 characters or more`
        : undefined
      : `Must be 8 characters or more`,
  PASSWORD_MATCH: (value, allValues) =>
    value !== allValues.password ? 'Passwords do not match' : undefined,
  PHONE_NUMBER: (value) =>
    value && !/^([0-9][0-9]{9})$/i.test(value)
      ? 'Invalid phone number, must be 10 digits'
      : undefined,
};

export const IMAGE_PATH = 'https://backend.planprogramrmutl.com/static/';
