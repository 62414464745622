import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { LoginFormStyled } from './styled';
import { VALIDATE } from 'helpers';
import FacebookLogin from 'react-facebook-login';
import { InputCustom } from 'components';

class LoginForm extends React.Component {
  state = {};

  render() {
    const {
      componentClicked,
      responseFacebook,
      handleClickLoginEmail,
      handleSubmit,
    } = this.props;
    return (
      <LoginFormStyled>
        <form onSubmit={handleSubmit(handleClickLoginEmail)}>
          <div className="title">Log in to meWoW</div>
          <div className="btn_layer">
            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              onClick={componentClicked}
              callback={responseFacebook}
              cssClass="btnFacebook"
              redirectUri={process.env.REACT_APP_SELF_ENDPOINT}
              disableMobileRedirect={true}
              icon="fa-facebook"
              textButton="Continue with Facebook"
            />
          </div>
          <div className="or_layer">
            <span className="or_span">OR</span>
          </div>
          <div className="form_layout">
            <div className="group_input">
              <div className="label_name">Email</div>
              <Field
                theme_log_in
                name="email"
                placeHolder="you@example.com"
                component={InputCustom}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="group_input">
              <div className="label_name">Password</div>
              <Field
                theme_log_in
                name="password"
                type="password"
                placeHolder="Password"
                component={InputCustom}
                validate={VALIDATE.REQUIRE}
              />
            </div>
            <div className="group_emailbtn">
              <button
                className="button"
              // onClick={handleClickLoginEmail}
              >
                LOG IN WITH EMAIL
              </button>
            </div>
          </div>
          {/* <div className="bottom_layout">
            <span>
              By logging in, you agree to meWoW{' '}
              <a href="terms">Terms of Service</a> and{' '}
              <a href="privacy">Privacy Policy</a>
            </span>
          </div> */}
          {/* <div className="forget_layout">
            <a href="/forgot">Forgot your password?</a>
          </div> */}
          {/* <div className="forget_layout" style={{ marginTop: '15px' }}>
            Don't have an account?
            <a href="/page/signup">Sign up</a>
          </div> */}
        </form>
      </LoginFormStyled>
    );
  }
}

export default reduxForm({
  form: 'LoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(LoginForm);
