import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CouponContainerStyled, CouponItemStyle } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { SideContainer, Pagination } from "components";
import { Helmet } from "react-helmet";
import { CouponForm } from "forms";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { InputSearchName, ButtonCustom } from "components";

const CouponContainer = (props) => {
  const [data, setData] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false)
  const [labelSave, setLabelSave] = useState("SAVE")
  const [isSave, setIsSave] = useState(true)
  const [searchInput, setSearchInput] = useState("")
  const [isAdd, setIsAdd] = useState(true)
  const [currentPageData, setCurrentPageData] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [pageList, setPageList] = useState(null)
  const [coupon_code, setCouponCode] = useState("")
  const [initialValues, setInitialValues] = useState(null)
  const [, setsubmitViewError] = useState(false)

  useEffect(() => {
    fetchData();
  }, [])
  useEffect(() => {
    const timer = setTimeout(async () => {
      let res = await adminService.SEARCH_COUPON(searchInput)
      if (res && res.success) {
        console.log(res.data)
        const { current_page } = res.page;
        setData(res.data)
        setCurrentPageData(current_page - 1)
        setPageList(res.page)
        setTotalPages(res.page.last_page)
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  const handlePageClick = (e) => {
    fetchData(e)
  }

  const fetchData = async (offset = 0) => {
    console.log('fetch')
    let res = await adminService.GET_COUPON_LIST(offset);
    if (res && res.success) {
      const { current_page } = res.page;
      setData(res.data)
      setCurrentPageData(current_page - 1)
      setPageList(res.page)
      setTotalPages(res.page.last_page)
    }
  };

  const handdleShowForm = () => {
    setIsShowForm(!isShowForm)
    setLabelSave("Save")
    setIsSave(true)
    setCouponCode("")
    setInitialValues(null)
  };

  const handleSubmit = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = props;

    if (typeof syncErrors === "object") {
      toast.error("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setsubmitViewError(true)
    } else {
      let params = {
        ...values,
        type: values.type ? values.type : "SINGLE",
      };

      if (
        params.discount_coins === undefined &&
        params.discount_percents === undefined
      ) {
        toast.error("กรุณากรอกข้อมูลให้ครบถ้วน", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setsubmitViewError(true)
      } else {
        if (isSave) {
          let res = await adminService.POST_COUPON_ADD(params);
          if (res && res.success) {
            toast.success("บันทึกสำเร็จ");
            fetchData();
            handdleShowForm();
          } else {
            toast.error("Code ซ้ำ");
          }
        } else {
          let res = await adminService.PATCH_COUPON_EDIT(params, coupon_code);
          if (res && res.success) {
            toast.success("บันทึกสำเร็จ");
            fetchData();
            handdleShowForm();
          } else {
            toast.error("บันทึกไม่สำเร็จ");
          }
        }
      }
    }
  };

  const handleDelete = (coupon_code) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCoupon(coupon_code),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteCoupon = async (coupon_code) => {
    let res = await adminService.DELETE_COUPON_DELETE(coupon_code);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      fetchData();
      handdleShowForm();
    } else {
      toast.error("ลบไม่สำเร็จ");
    }
  };

  const handdleClickDetail = (e) => {
    setInitialValues({ ...e })
    setCouponCode(e.code)
    setIsShowForm(true)
    setIsSave(false)
    setLabelSave("Save")
    setIsAdd(false)
  };

  const handleClickCloseModal = () => {
    setIsShowForm(false)
  };

  const renderCoupon = () => {
    return data.length === 0 ? false : data
  };

  return (
    <CouponContainerStyled>
      <Helmet>
        <title>admin</title>
      </Helmet>
      <div className="main_layout">
        <div className="tab_layout">
          <h2 className="title_layout">Coupon System</h2>
          <div style={{ marginLeft: 10 }}>
            <ButtonCustom
              theme_yellow_2
              label="CREATE"
              onClick={handdleShowForm}
            />
          </div>
        </div>
        <div className="search_container">
          <InputSearchName
            theme_mobile_standard
            placeHolder="Search Code/Discount"
            handleSearch={(e) => setSearchInput(e)}
          />
          {searchInput !== "" ? (
            <div>
              <p className="search_title_result">{`Search : "${searchInput}" `}</p>
            </div>
          ) : null}
        </div>
        <div className="coupon_container">
          {renderCoupon() ? (
            renderCoupon().map((coupon) => {
              return (
                <CouponItem
                  key={coupon.code}
                  {...coupon}
                  handleDelete={handleDelete}
                  handdleClickDetail={handdleClickDetail}
                />
              );
            })
          ) : (
              <h1 style={{ paddingLeft: "0.5rem" }}>Coupon Not Found</h1>
            )}
        </div>
        <Pagination
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(e) => handlePageClick(e.selected * pageList?.per_page)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPageData}
        />
        <div className="form_layout">
          <SideContainer title={isAdd ? "New Coupon" : "Edit Coupon"} isShow={isShowForm} handleClose={handleClickCloseModal}>
            <div className="form_layout">
              <CouponForm
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
                labelSave={labelSave}
                isSave={isSave}
                initialValues={initialValues}
              />
            </div>
          </SideContainer>
        </div>
      </div>
    </CouponContainerStyled>
  );
}


const CouponItem = ({ type, code, end_date, id, is_active, discount_coins, discount_percents, handdleClickDetail }) => {
  return (
    <CouponItemStyle type={type} is_active={is_active} onClick={() => handdleClickDetail({ code, id, is_active, discount_coins, end_date, discount_percents })}>
      <div className="ribbon ribbon-top-right"><span>{type}</span></div>
      <div className="content">
        <div className="expire">
          <span>Expire : </span>
          <div>{`${end_date ?? "-"}`}</div>
        </div>
        <span className="code">{id % 2 === 0 ? code : `${code}`}</span>
        <span >Discount : {parseInt(discount_percents)}% {discount_coins ? `or ${parseInt(discount_coins)}฿` : ""}</span>
      </div>
    </CouponItemStyle>
  )
}
const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CouponForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer);
