import { ROUTE_PATH } from "helpers";
import CreatorContainer from "containers/AdminSystem/CreatorSystem/CreatorContainer";
import AgencyContainer from "containers/AdminSystem/AgencySystem/AgencyContainer";
import AgentContainer from "containers/AdminSystem/AgentSystem/AgentContainer";
import OrderContainer from "containers/AdminSystem/OrderSystem/OrderContainer";
import CouponContainer from "containers/AdminSystem/CouponSystem/CouponContainer";
import VoteContainer from "containers/AdminSystem/VoteSystem/VoteContainer";
import CreatorDetailContainer from "containers/AdminSystem/CreatorSystem/CreatorDetailContainer";
import GenreContainer from "containers/AdminSystem/GenreSystem/GenreContainer";
import GenreDetailContainer from "containers/AdminSystem/GenreSystem/GenreDetailContainer";
import RefundContainer from "containers/AdminSystem/RefundSystem/RefundContainer";
import RefundDetailContainer from "containers/AdminSystem/RefundSystem/RefundDetailContainer";
import AccountingContainer from "containers/AdminSystem/AccountingSystem/AccountingContainer";
// import AccountingDetailContainer from 'containers/AdminSystem/AccountingSystem/AccountingDetailContainer';
import EnrollContainer from "containers/AdminSystem/EnrollSystem/EnrollContainer";
import WheelContainer from "containers/AdminSystem/WheelSystem/WheelContainer";

import {
  OrderIcon,
  CreatorIcon,
  CategoryIcon,
  RefundIcon,
  AccountingIcon,
  CouponIcon,
  VoteIcon,
  CompanyIcon,
  AgentIcon,
  EnrollIcon,
  WheelIcon,
} from "components";

var cmsRoutes = [
  {
    keyIndex: 1,
    path: ROUTE_PATH.A_CREATOR,
    exact: true,
    name: "Creators",
    icon: CreatorIcon,
    component: CreatorContainer,
  },
  {
    keyIndex: 1.1,
    path: ROUTE_PATH.A_CREATOR_DETAIL,
    exact: true,
    name: "Creator Detail",
    icon: "Creator Detail",
    notShow: true,
    component: CreatorDetailContainer,
  },
  {
    keyIndex: 5,
    path: ROUTE_PATH.A_ENROLL,
    exact: true,
    name: "Enroll",
    icon: EnrollIcon,
    component: EnrollContainer,
  },
  {
    keyIndex: 2,
    path: ROUTE_PATH.A_GENRE,
    exact: true,
    name: "Genre",
    icon: CategoryIcon,
    component: GenreContainer,
  },
  {
    keyIndex: 2.1,
    path: ROUTE_PATH.A_GENRE_DETAIL,
    exact: true,
    name: "Genre Detail",
    icon: "Genre Detail",
    notShow: true,
    component: GenreDetailContainer,
  },
  {
    keyIndex: 3,
    path: ROUTE_PATH.A_REFUND,
    exact: true,
    name: "Refund",
    icon: RefundIcon,
    component: RefundContainer,
  },
  {
    keyIndex: 3.1,
    path: ROUTE_PATH.A_REFUND_DETAIL,
    exact: true,
    name: "Refund Detail",
    icon: "Refund Detail",
    notShow: true,
    component: RefundDetailContainer,
  },
  {
    keyIndex: 4,
    path: ROUTE_PATH.A_ACCOUNTING,
    exact: true,
    name: "Accounting",
    icon: AccountingIcon,
    component: AccountingContainer,
  },
  // {
  //   keyIndex: 4.1,
  //   path: ROUTE_PATH.A_ACCOUNTING_DETAIL,
  //   exact: true,
  //   name: 'Accounting Detail',
  //   icon: 'Accounting Detail',
  //   notShow: true,
  //   component: AccountingDetailContainer,
  // },
  {
    keyIndex: 5,
    path: ROUTE_PATH.A_ORDER,
    exact: true,
    name: "Order",
    icon: OrderIcon,
    component: OrderContainer,
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.A_COUPON,
    exact: true,
    name: "Coupon",
    icon: CouponIcon,
    component: CouponContainer,
  },
  {
    keyIndex: 6,
    path: ROUTE_PATH.A_VOTE,
    exact: true,
    name: "Vote",
    icon: VoteIcon,
    component: VoteContainer,
  },
  {
    keyIndex: 7,
    path: ROUTE_PATH.A_AGENCY,
    exact: true,
    name: "Company",
    icon: CompanyIcon,
    component: AgencyContainer,
  },
  {
    keyIndex: 8,
    path: ROUTE_PATH.A_AGENT,
    exact: true,
    name: "AR",
    icon: AgentIcon,
    component: AgentContainer,
  },
  {
    keyIndex: 9,
    path: ROUTE_PATH.A_WHEEL,
    exact: true,
    name: "Wheel",
    icon: WheelIcon,
    component: WheelContainer,
  },
];

export default cmsRoutes;
