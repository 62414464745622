import React from "react";

const EditIcon = ({ size = 40, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-80 -50 640 640"
    width={size}
    height={size}
    fill={fill}
  >
    <path d="M434.932 488A12.218 12.218 0 00447 475.815V285h-24v179H51V83h151V59H39.058A12.341 12.341 0 0027 71.336v404.479A12.209 12.209 0 0039.058 488z" />
    <path d="M407.647 27.777A12 12 0 00390.6 27.7L130.113 288.186a12 12 0 00-3.435 7.1l-10.1 87.142a12 12 0 0013.607 13.262l83.953-11.922a12 12 0 006.8-3.4l260.49-260.483a12 12 0 00.077-16.893zM206.8 360.573l-64.589 9.172 7.829-67.543L354.162 98.077l56.953 58.179zm221.29-221.289l-56.956-58.179 27.873-27.873 57.042 58.09z" />
  </svg>
);

export default EditIcon;
