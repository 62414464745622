import React from 'react'
import { Chip } from "components";
import { OptionBullet, CardContainer } from "../styled";
import Person from "../../../../../assets/img/icon/person.png";
const CreatorCard = ({
  displayname,
  description,
  posters,
  onClick,
  about,
  genres,
  is_explore,
  is_highlight,
  is_newarrival,
  is_upcoming,
  is_active = 1
}) => {
  return (
    <CardContainer options={{ is_explore, is_highlight, is_newarrival, is_upcoming, is_active }} className="creator_card">
      <div className="creator_card_container" onClick={() => onClick()}>
        <div className="un_activate" onClick={() => onClick()}></div>
        <div className="card_image_container" >
          <img src={
            posters.length === 0 || posters[0].type === "video"
              ? Person
              : posters[0].uri
          }
            alt="pic"
            width={100}
            height={130}
            className="image"
            style={{ objectFit: 'cover' }} />
        </div>

        <div className="card_content">
          <div className="card_header_genre">
            {genres.length > 0 ? genres.slice(0, 2).map((genre, i) => {
              return (
                <Chip fontSize={"13px"} key={i}>
                  <span style={{ display: "block" }}> {genre.label}</span>
                </Chip>
              )
            }) : null}
          </div>
          <div className="card_detail">
            <div className="card_title">{displayname}</div>
            <div className="card_description">{description ?? "-"}</div>
            <div>
              <div className="card_about">{about.substring(0, 60)}...</div>
            </div>
          </div>
        </div>
        <div className="card_option">
          <OptionBullet is_option={is_highlight === 1 ? false : true}>H</OptionBullet>
          <OptionBullet is_option={is_upcoming === 1 ? false : true}>U</OptionBullet>
          <OptionBullet is_option={is_newarrival === 1 ? false : true}>N</OptionBullet>
          <OptionBullet is_option={is_explore === 1 ? false : true}>E</OptionBullet>
        </div>
      </div >
    </CardContainer >
  );
};

export default CreatorCard