import React from "react";

const WheelIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 125 125"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M45.8 46.2h-13c-1.1 0-2 .9-2 2v3.5c0 1.1.9 2 2 2h13v13c0 1.1.9 2 2 2h3.5c1.1 0 2-.9 2-2v-13h13c1.1 0 2-.9 2-2v-3.5c0-1.1-.9-2-2-2h-13v-13c0-1.1-.9-2-2-2h-3.5c-1.1 0-2 .9-2 2v13zM22.9 71c-1.5 1.5-1.5 3.8 0 5.3 5 5.1 11.4 8.7 18.3 10.3 2 .5 4.1-.7 4.6-2.7s-.7-4.1-2.7-4.6c-.1 0-.2 0-.2-.1-5.6-1.3-10.7-4.1-14.7-8.2-1.5-1.4-3.9-1.4-5.3 0z" />
    <path d="M56.2 79.2c-2 .4-3.3 2.4-2.9 4.4.4 2 2.4 3.3 4.4 2.9h.2C78.1 82 90.7 61.8 86.1 41.7 82.2 24.6 67 12.5 49.5 12.5c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8c16.6 0 30 13.4 30 30 .1 13.9-9.6 26-23.3 29.1zM12 50c0 4.6.8 9.2 2.5 13.5.7 1.9 2.9 2.9 4.9 2.1 1.9-.7 2.9-2.9 2.1-4.9-1.3-3.5-2-7.1-2-10.8 0-2.1-1.7-3.8-3.8-3.8S12 47.9 12 50zM22.2 29.8c-1.8-1-4.1-.4-5.1 1.4-.4.7-.8 1.4-1.1 2.1-.9 1.8-.2 4.1 1.6 5s4.1.2 5-1.6v-.1c.3-.6.6-1.1.9-1.7 1.1-1.7.5-4-1.3-5.1z" />
  </svg>
);

export default WheelIcon;
