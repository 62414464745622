import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { CreatorGenreFormSec3Styled } from "./styled";
import { adminService } from "apiService";
import { VALIDATE } from "helpers";
import { InputDropdown, Card, Chip } from "components";
import { connect } from "react-redux";

const selector = formValueSelector("CreatorGenreFormSec3");

class CreatorGenreFormSec3 extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await adminService.GET_GENRES_LIST();
    if (res && res.success) {
      this.setState({
        genreOptions: res.data.map((e, i) => ({
          label: e.label,
          value: e.slug,
        })),
      });
    }
  };

  render() {
    const { genreOptions } = this.state;
    const {
      data,
      handleSubmit,
      handdleSave,
      handleRemoveGenre,
    } = this.props;


    genreOptions && genreOptions.map((e, i) => (
      data && data.map((e1, j) => (
        e.label === e1.label ? genreOptions.splice(i, 1) : ''
      ))
    ))


    return (
      <CreatorGenreFormSec3Styled>
        <form
          ref={(ref) => (this.myForm = ref)}
          onSubmit={handleSubmit(handdleSave)}
        >
          <Card title="Creator Genre">
            <div className="box_label">
              <div className="label">Add Genere</div>
              <Field
                theme_standard
                name="slug"
                options={genreOptions}
                component={InputDropdown}
                validate={VALIDATE.REQUIRE}
                onChange={(e) => {
                  handdleSave(e.target.value);
                }}
              />
            </div>
            <div className="genre_label">
              {data &&
                data.map((e, i) => (
                  <Chip onClick={() => handleRemoveGenre(e.slug)} key={i}>
                    {e.label}
                  </Chip>
                ))}
            </div>
          </Card>
        </form>
      </CreatorGenreFormSec3Styled>
    );
  }
}

const mapStateToProps = (state) => {
  const slug = selector(state, "slug");
  return {
    slug,
  };
};

const mapDispatchToProps = {
  change,
};

export default reduxForm({
  form: "CreatorGenreFormSec3", // a unique identifier for this form
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(CreatorGenreFormSec3));
