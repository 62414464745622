import React from 'react';
import { connect } from 'react-redux';
import { GenreProfileContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { GenreForm } from 'forms/AdminFormSystem';

class GenreProfileContainer extends React.Component {
  state = {};

  componentDidMount() {
    const { slugId } = this.props;
    if (slugId) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { slugId } = this.props;
    let res = await adminService.GET_ADMIN_GENRES_DETAIL(slugId);
    if (res && res.success) {
      this.setState({
        data: res.data,
        initialValues: {
          ...res.data,
          // user_id: res.data.user ? String(res.data.user.name) : undefined,
        },
      });
    }
  };

  handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
      slugId,
    } = this.props;

    const params = {
      ...values,
      is_active: values.is_active ? values.is_active : 0,
    };

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (!slugId) {
        let res = await adminService.POST_GENRES_ADD(params);
        if (res && res.success) {
          toast.success('บันทึกสำเร็จ');
          this.props.handleAddSuccess()
          // callback && callback(res.data.slug);
          // this.props.history.push(ROUTE_PATH.A_AGENT);
        } else {
          toast.error('Slug นี้มีข้อมูลแล้วกรุณาเปลี่ยน');
        }
      } else {
        let res = await adminService.PATCH_GENRE_UPDATE(slugId, params);
        if (res && res.success) {
          toast.success('บันทึกสำเร็จ');
          // this.props.history.push(ROUTE_PATH.A_AGENT);
        } else {
          toast.error('Slug นี้มีข้อมูลแล้วกรุณาเปลี่ยน');
        }
      }
    }
  };

  render() {
    const { initialValues } = this.state;
    return (
      <GenreProfileContainerStyled>
        <GenreForm
          initialValues={initialValues}
          handdleSave={this.handdleSave}
          isAdd={this.props.isAdd}
        />
      </GenreProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.GenreForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenreProfileContainer);
