import styled from "styled-components";
import { SCREEN } from "assets";

export const CreatorDetailContainerStyled = styled.div`
  position: relative;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 2rem;

  background-color: white;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    min-width: 900px;
  }

  .form_item_layout {
    margin: 24px 0;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .form-wrapper {
      min-width: initial;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .form-wrapper {
      min-width: initial;
    }
  }
`;
