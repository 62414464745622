import styled from "styled-components";
import { SCREEN } from "assets";

export const SidebarStyled = styled.div`
  height: 100vh;
  min-width: ${({ isShow }) => (isShow ? "250px" : "60px")};
  position: fixed;
  display: flex;
  flex-direction: column;

  padding-bottom: 60px;

  background-color: white;
  box-shadow: 4px 0px 10px -2px rgba(0, 0, 0, 0.15);
  z-index: 10;
  transition: all 0.2s ease-in-out;
  font-family: prompt_light;

  .dismiss-section {
    display: flex;
    height: 60px;
    min-height: 60px;
    position: relative;
  }
  .dismiss-button {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateY(-50%);
  }
  .expand-side-bar {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    background-color: white;
    cursor: pointer;

    span {
      display: ${({ isShow }) => (isShow ? "flex" : "none")};
      color: black;
    }
  }
  .chevron-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;

    transition: all 0.2s ease-in-out;
    transform: rotate(${({ isShow }) => (isShow ? "-180" : "0")}deg);
  }
  .header {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px 0;

    background-color: white;

    .img_header {
      width: 180px;
      height: 110px;
    }
    .img_header2 {
      width: 50px;
      height: 80px;
    }
  }
  .nav-link-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-left: ${({ isShow }) => (isShow ? "12px" : "0")};

    overflow: auto;

    .menu-name {
      display: ${({ isShow }) => (isShow ? "flex" : "none")};
    }

    > .menu {
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: ${({ isShow }) => (isShow ? "initial" : "center")};
      position: relative;

      border-left: ${({ isShow }) =>
        isShow ? "none" : "4px solid transparent"};

      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
      > .icon-wrapper {
        opacity: 0.5;
      }
    }
    > .active-menu {
      position: relative;

      border-top-left-radius: ${({ isShow }) => (isShow ? "50px" : "0")};
      border-bottom-left-radius: ${({ isShow }) => (isShow ? "50px" : "0")};
      border-left: ${({ isShow }) => (isShow ? "none" : "4px solid #000066")};

      background-color: ${({ isShow }) =>
        isShow ? "#000066" : "rgba(0,0,0,0.05)"};
      color: white;

      svg {
        fill: ${({ isShow }) => (isShow ? "white" : "black")};
      }
      > .icon-wrapper {
        opacity: 1;
      }
      ::before,
      ::after {
        width: 50px;
        height: 50px;
        position: absolute;
        top: -50px;
        right: 0;

        ${({ isShow }) => (isShow ? "content: '';" : "")};
        background-color: #000066;
        z-index: -1;
      }
      ::after {
        border-bottom-right-radius: 50%;

        background-color: white;
      }
    }
    > .active-menu + .menu::before,
    > .active-menu + .menu::after {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;

      ${({ isShow }) => (isShow ? "content: '';" : "")};
      background-color: #000066;
      z-index: -1;
    }
    > .active-menu + .menu::after {
      border-top-right-radius: 50%;

      background-color: white;
    }
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: ${({ isShow }) => (isShow ? "0 8px 0 12px" : "0")};
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .header {
      height: 25%;
      .img_header {
        width: 150px;
        height: 90px;
      }
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    min-width: ${({ isShow }) => (isShow ? "initial" : "0")};
    overflow: ${({ isShow }) => (isShow ? "initial" : "hidden")};
    width: ${({ isShow }) => (isShow ? "100%" : "0%")};
    padding-bottom: 20px;
  }
`;
