import styled from 'styled-components';
// import {
//     COLOR,
//     FONT
// } from 'assets'

export const GenreUploadFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
padding : 3rem;
  .item_layout {
    margin-bottom: 10px;
  }
  .button_layer {
    text-align: right;
  }
`;
