import React from "react";
import { Field, reduxForm } from "redux-form";
import { AgentProfileFormStyled } from "./styled";
import { VALIDATE } from "helpers";
import {
  Input,
  ButtonCustom,
  InputCheckbox,
  InputSuggestUser,
} from "components";

class AgentProfileForm extends React.Component {
  state = {};

  render() {
    const { handdleSave, handleSubmit } = this.props;
    return (
      <AgentProfileFormStyled>
        <form onSubmit={handleSubmit(handdleSave)}>
          <div className="box_label">
            <div className="label">Name</div>
            <Field
              theme_standard_2
              name="displayname"
              placeholder="Enter Name"
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
            <div className="label">Description</div>
            <Field
              theme_standard_2
              name="description"
              placeholder="Enter Description"
              component={Input}
              validate={VALIDATE.REQUIRE}
            />
          </div>
          <div className="box_label">
            <div className="label">User</div>
            <Field
              theme_standard
              name="user_id"
              label="Highlight"
              component={InputSuggestUser}
            />
          </div>
          <div className="box_label">
            <div className="label">Option</div>
            <div className="item_checkout">
              <Field
                theme_standard
                name="is_active"
                label="Active"
                value={true}
                component={InputCheckbox}
              />
            </div>
          </div>
          <div className="button_layer">
            <ButtonCustom theme_yellow_2 label="SAVE" />
          </div>
        </form>
      </AgentProfileFormStyled>
    );
  }
}

export default reduxForm({
  form: "AgentProfileForm", // a unique identifier for this form
  enableReinitialize: true,
})(AgentProfileForm);
