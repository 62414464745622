import React, { useState, useEffect } from "react";
import { SideContainerStyled } from "./styled";
import { CrossIcon } from "components";

export const SideContainer = ({ title, children, isShow, handleClose }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isShow) {
      setShow(true);
    }
  }, [isShow]);

  return (
    show && (
      <SideContainerStyled isShow={isShow}>
        <div className="side-bar-container">
          <div className="sidebar-dismiss-button" onClick={handleClose}>
            <CrossIcon size={30} />
          </div>
          <span className="sidebar-title">{title}</span>
          {children}
        </div>
        <div className="side-bar-opacity" onClick={handleClose} />
      </SideContainerStyled>
    )
  );
};
