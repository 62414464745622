import styled from "styled-components";
import { SCREEN } from "assets";

export const GenreDetailContainerStyled = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  align-items: center;

  background-color: white;

  .form-wrapper {
    flex-direction: column;
  }

  .form_item_layout {
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .form-wrapper {
      min-width: initial;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .form-wrapper {
      min-width: initial;
    }
  }
`;
