import styled from "styled-components";
import { SCREEN } from "assets";

export const AgentDetailContainerStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    min-width: 900px;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .form-wrapper {
      min-width: initial;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .form-wrapper {
      min-width: initial;
    }
  }
`;
