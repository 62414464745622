import styled from "styled-components";
import { SCREEN } from "assets";

export const AgentProfileFormStyled = styled.div`
  input[type="text"] {
    width: 100%;
    height: 35px;

    border-radius: 4px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 8px;
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
  }
  .box_label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .label {
      min-width: 100px;
    }
    .item_checkout {
    }
  }
  .button_layer {
    text-align: right;
  }

  @media (max-width: ${SCREEN.PHONE}) {
    .box_label {
      flex-direction: column;
      align-items: flex-start;
      .label {
        text-align: left;
      }
    }
  }
`;
