import apiService from '../apiService';

const apiPath = 'api';

export const userService = {
  //SYSTEM MEMBER
  POST_LOG_IN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  POST_CHECK_TOKEN_EXPIRE: (params) => {
    return apiService.post(`${apiPath}/loginWithToken`, params);
  },
  GET_LOGOUT: () => {
    return apiService.get(`${apiPath}/logout`);
  },

  //PROFILE
  GET_PROFILE_DETAIL: () => {
    return apiService.get(`${apiPath}/users/me`);
  },
  POST_PROFILE_REGISTER: (params) => {
    return apiService.post(`${apiPath}/users`, params);
  },
  PATCH_PROFILE_UDATE_DETAIL: (params) => {
    return apiService.patch(`${apiPath}/users/me`, params);
  },

  //CREATOR
  GET_CREATOR_LIST: () => {
    return apiService.get(`${apiPath}/creators`);
  },
  GET_CREATOR_DETAIL: (creatorID) => {
    return apiService.get(`${apiPath}/creators/${creatorID}`);
  },
  POST_ENROLL_CREATOR: (params) => {
    return apiService.post(`${apiPath}/applicants`, params);
  },
  GET_CREATOR_SEARCH_LIST: (key) => {
    return apiService.get(`${apiPath}/creators?keyword=${key}&all=true`);
  },
  GET_CREATOR_SERVICES_LIST: (creatorID) => {
    return apiService.get(`${apiPath}/creators/${creatorID}/services`);
  },

  //HIGHLIGHT
  GET_HIGHLIGHT_ALL_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/highlight?all=true`);
  },
  GET_HIGHLIGHT_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/highlight`);
  },

  // NEW ARRIVAL
  GET_NEW_ARRIVAL_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/newarrival?all=true`);
  },

  //EXPLORE
  GET_EXPLORE_ALL_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/explore?all=true`);
  },
  GET_EXPLORE_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/explore`);
  },

  //UPCOMING
  GET_UPCOMING_ALL_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/upcoming?all=true`);
  },
  GET_UPCOMING_LIST: () => {
    return apiService.get(`${apiPath}/creators/genres/upcoming`);
  },

  //CATEDGORY
  GET_CATEDGORY_LIST: () => {
    return apiService.get(`${apiPath}/categories`);
  },
  GET_CATEDGORY_GROUP_LIST: () => {
    return apiService.get(`${apiPath}/categories/groups`);
  },
  GET_CATEDGORY_GROUP_LIST_SERVICE: (creator_id, service_id) => {
    return apiService.get(
      `${apiPath}/creators/${creator_id}/services/${service_id}`
    );
  },

  //CATEGORIES
  GET_CATEGORIES_LIST: () => {
    return apiService.get(`${apiPath}/genres`);
  },
  GET_CATEGORIES_DETAIL: (genre_slug) => {
    return apiService.get(`${apiPath}/genres/${genre_slug}`);
  },
  GET_CATEGORIES_CREATOR: (genre_slug) => {
    return apiService.get(`${apiPath}/genres/${genre_slug}/creators`);
  },

  //ORDER
  POST_ORDERS_DETAIL: (params) => {
    return apiService.post(`${apiPath}/orders`, params);
  },

  //BOOKING
  GET_BOOKING_LIST: () => {
    return apiService.get(`${apiPath}/gifters/me/orders`);
  },
  GET_BOOKING_DETAIL: (orderID) => {
    return apiService.get(`${apiPath}/gifters/me/orders/${orderID}`);
  },
  DELETE_BOOKING_DETAIL: (orderID) => {
    return apiService.delete(`${apiPath}/gifters/me/orders/${orderID}`);
  },
  PATCH_BRIEF_DETAIL: (order_id, params) => {
    return apiService.patch(`${apiPath}/gifters/me/orders/${order_id}`, params);
  },

  //PAYMENT
  GET_PAYMENT_DETAIL: (orderID) => {
    return apiService.get(`${apiPath}/gifters/me/orders/${orderID}/payment/qr`);
  },
  GET_PAYMENT_PAYPAL_DETAIL: (orderID) => {
    return apiService.get(
      `${apiPath}/gifters/me/orders/${orderID}/payment/paypal`
    );
  },
  GET_PAYMENT_PAYPAL_SUCCESS: (token) => {
    return apiService.get(`${apiPath}/payment/success?token=${token}`);
  },
  GET_PAYMENT_PAYPAL_CANCLE: (token) => {
    return apiService.get(`${apiPath}/payment/cancel?token=${token}`);
  },

  // VOTE
  GET_VOTE_LIST: () => {
    return apiService.get(`${apiPath}/candidates`);
  },
  GET_VOTE_LIST_BY_SEARCH: (keyword) => {
    return apiService.get(`${apiPath}/candidates?keyword=${keyword}`);
  },
  POST_VOTE_DETAIL: (candidateID, params) => {
    return apiService.post(
      `${apiPath}/candidates/${candidateID}/votes`,
      params
    );
  },

  // REFERAL
  GET_REFERAL_DETAIL: (refID) => {
    return apiService.get(`${apiPath}/referrals/${refID}`);
  },

  //FOLLOW
  GET_ME_FOLLOW_LIST: () => {
    return apiService.get(`${apiPath}/gifters/me/followings`);
  },
  POST_ME_FOLLOW_DETAIL: (params) => {
    return apiService.post(`${apiPath}/gifters/me/followings`, params);
  },
  DELETE_ME_FOLLOW_DETAIL: (creatorID) => {
    return apiService.delete(`${apiPath}/gifters/me/followings/${creatorID}`);
  },

  //COUPON
  GET_COUPON_DETAIL: () => {
    return apiService.get(`${apiPath}/gifters/me/coupons/auto`);
  },
  GET_COUPON_CAL_DETAIL: (type, price) => {
    return apiService.get(
      `${apiPath}/gifters/me/coupons/${type}?rate_coins=${price}`
    );
  },

  //BANK
  GET_BANK_LIST: () => {
    return apiService.get(`${apiPath}/banks`);
  },

  //REFUND
  POST_REFUND_DETAIL: (invoiceID, params) => {
    return apiService.post(`${apiPath}/invoices/${invoiceID}/refunds`, params);
  },

  //V
  GET_V_DETAIL: (uuID) => {
    return apiService.get(`${apiPath}/videos/${uuID}`);
  },

  // PAYMENT
  GET_PAYMENT_DETAIL_BY_INVOICE: (invoiceID) => {
    return apiService.get(`${apiPath}/invoices/${invoiceID}/status`);
  },
  POST_PAYMENT_DETAIL: (invoiceID, params) => {
    return apiService.post(`${apiPath}/invoices/${invoiceID}/payments`, params);
  },

  //REVIEW
  POST_REVIEW__DETAIL: (uuID, params) => {
    return apiService.post_formdata(
      `${apiPath}/videos/${uuID}/reviews`,
      params
    );
  },

  // FAN SHARE
  GET_FAN_SHARE_LIST: () => {
    return apiService.get(`${apiPath}/videos/public`);
  },

  // PUT_FORM_TEMPLATE_UPDATE: (id, params) => {
  //   return apiService.put(`${apiPath}/form_template/update/${id}`, params);
  // },
  // DELETE_FORM_TEMPLATE_DETAIL: (id) => {
  //   return apiService.delete(`${apiPath}/form_template/delete/${id}`);
  // },
};
