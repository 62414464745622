import React, { useRef, useState } from 'react';
import { InputUploadFIleStyled } from './styled';
import cx from 'classnames';
import { ButtonCustom } from 'components';
import Upload from '../../assets/img/icon/upload.svg'
export const InputUploadFIle = ({
  theme_standard,
  label,
  buttonLabel,
  input,
  disabled,
  width,
  height,
  showOnly,
  placeholder,
  title,
  haveFilePhoto,
  haveFileVideo,
  accept,
  meta: { touched, error },

}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const inputEl = useRef(null);
  const [nameUpload, setNameUpload] = useState();
  const _onChange = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.includes("image")) {
        setNameUpload(e.target.files[0].name);
        input.onChange(e.target.files[0]);
      }
      else {
        input.onChange(e.target.files[0]);
        setNameUpload(e.target.files[0].name);
      }
    }
  };
  return (
    <InputUploadFIleStyled isInvalid={touched && error} width={width}>
      {console.log(input.value)}
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        {showOnly ? (
          input.value
        ) : (
            <>
              <div className="upload_btn_location">
                {/* {nameUpload ? (
                  <div className="label_upload">{nameUpload}</div>
                ) : ( */}
                {/* {input.value && (haveFilePhoto || haveFileVideo)} */}
                {input.value ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h3 style={{ marginRight: 10 }}>{title}</h3>
                      <ButtonCustom
                        theme_yellow_2
                        type="button"
                        label={<span style={{ display: 'flex', justifyContent: 'center' }}><img src={Upload} alt="upload" width={23} height={23} style={{ marginRight: 5 }} />{buttonLabel}</span>}
                        onClick={() => inputEl.current.click()}
                      />
                    </div>
                    <div>
                      {nameUpload ? <div className="label_upload">{nameUpload}</div> : <a className="label_link" target="_blank" rel="noopener noreferrer" href={input.value}>
                        link
                          </a>}
                    </div>
                  </div>
                ) : (
                    <>

                      <h3 className="label_link_not">N/A</h3>
                      <ButtonCustom
                        theme_yellow_2
                        type="button"
                        label={<span style={{ display: 'flex', justifyContent: 'center' }}><img src={Upload} alt="upload" width={23} height={23} style={{ marginRight: 5 }} />{buttonLabel}</span>}
                        onClick={() => inputEl.current.click()}
                      />
                    </>

                  )}

                <input
                  type="file"
                  ref={inputEl}
                  hidden
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={_onChange}
                  accept={accept}
                />
                {touched && error && <div className="error">{error}</div>}
              </div>
            </>
          )}
      </div>
    </InputUploadFIleStyled>
  );
};
