import styled from "styled-components";

export const WheelDetailStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 12px;

  .section {
    display: flex;
    align-items: center;

    margin-bottom: 12px;
  }
  .title {
    min-width: 100px;

    margin-right: 12px;
  }
  .button-wrapper {
    margin-top: 12px;
    justify-content: flex-end;
  }

  input {
    display: flex;
    align-items: center;
    height: 35px;
    min-height: 35px;
    width: 100%;

    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 0 12px;

    outline: none;
  }
`;
