import styled from "styled-components";
import {
  COLOR,
  // FONT
} from "assets";

export const GenreFormStyled = styled.div`
  input[type="text"] {
    width: 100%;
    height: 35px;

    border-radius: 4px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.6);
    padding: 8px;
  }
  ${(props) => (props.isAdd ? "" : "padding : 3rem;")}
  .item_init {
    .label {
      span {
        margin-left: 5px;
        color: ${COLOR.RED_1};
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .box_label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .label {
      min-width: 100px;
      margin-right: 10px;
    }
    .item_checkout {
      margin-right: 8px;
    }
  }
  .group_show {
    margin-bottom: 10px;
  }
  .button_layer {
    text-align: right;
  }
`;

export const SwitchButton = styled.label`
  display: flex;
  width: 48px;
  height: 24px;
  position: relative;

  input {
    display: none;
  }
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 24px;

    background-color: #c0c0c0;
    transition: 0.4s;
    cursor: pointer;
  }
  .slider:before {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 4px;

    border-radius: 24px;

    content: "";
    transform: translateY(-50%);
    background-color: white;
    transition: 0.4s;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3), inset 0 0 4px rgba(0, 0, 0, 0.05);
  }
  input:checked + .slider {
    background-color: #10cf6f;
  }
  input:checked + .slider:before {
    transform: translate(20px, -50%);
  }
`;