import styled from "styled-components";
import { SCREEN } from "assets";

export const AgencyDetailContainerStyled = styled.div`
  height: 100%;
  width: 100%;

  background-color: white;
  .line {
    width: 100%;

    margin-top: 1rem;
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
  }

  .form_item_layout {
    margin: 1rem 0;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .form-wrapper {
      min-width: initial;
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .form-wrapper {
      min-width: initial;
    }
  }
`;
