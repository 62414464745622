import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AgencyDetailContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import {
  AgentContainer,
  AgencyProfileContainer,
  CreatorContainer,
} from "../SectionsAgencyDetail";

const AgencyDetailContainer = (props) => {
  const [agencyID, setAgentcyID] = useState(props.detailId)


  useEffect(() => {
    const fetchData = async () => {
      if (props.detailId !== "create") {
        setAgentcyID(props.detailId)
      }
    }
    fetchData();
  }, [props.detailId, agencyID])

  return (
    <AgencyDetailContainerStyled>
      <div className="form-wrapper">
        <div className="form_item_layout">
          <AgencyProfileContainer
            agencyID={
              agencyID
            }
            handleEditSuccess={props.handleEditSuccess}
          />
        </div>
        <div className="line" />
        {agencyID && (
          <>
            <div className="form_item_layout">
              <AgentContainer agencyID={agencyID} />
            </div>
            <div className="line" />
            <div className="form_item_layout last">
              <CreatorContainer agencyID={agencyID} />
            </div>
          </>
        )}
      </div>
    </AgencyDetailContainerStyled>
  )
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorProfileFormSec1,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyDetailContainer);
