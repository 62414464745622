import React from 'react';
import { connect } from 'react-redux';
import { CreatorUploadContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { CreatorUploadFormSec2 } from 'forms/AdminFormSystem';

class CreatorUploadContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { creatorID } = this.props;
    let res = await adminService.GET_CREATOR_DETAIL(creatorID);
    if (res && res.success) {
      let photo;
      let video;
      if (res.data.posters.length > 0) {
        photo = res.data.posters.find((e) => e.type === 'photo');
        photo = photo ? photo.uri : undefined;
        video = res.data.posters.find((e) => e.type === 'video');
        video = video ? video.uri : undefined;
      }

      this.setState({
        initialValues: {
          photo_file: photo,
          video_file: video,
        },
      });
    }
  };

  handdleSave = async () => {
    this.setState({ loading: true })
    const {
      reduxForm: { values, syncErrors },
      creatorID,
    } = this.props;
    let params = {
      ...values,
    };
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let formData = new FormData();
      if (params.photo_file && typeof params.photo_file === 'object')
        formData.append('photo_file', params.photo_file);
      if (params.video_file && typeof params.video_file === 'object')
        formData.append('video_file', params.video_file);
      let res = await adminService.UPLOAD_CREATOR(creatorID, formData);
      if (res && res.success) {
        toast.success('บันทึกสำเร็จ');
        this.fetchData();
      } else {
        toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
      }
      this.setState({ loading: false })
    }
  };
  render() {
    const { initialValues } = this.state;
    return (
      <CreatorUploadContainerStyled>
        <CreatorUploadFormSec2
          initialValues={initialValues}
          handdleSave={this.handdleSave}
          loading={this.state.loading}
        />
      </CreatorUploadContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.CreatorUploadFormSec2,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorUploadContainer);
