import React from "react";

const AccountingIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-20 -20 150 150"
    height={size}
    width={size}
    fill={fill}
  >
    <path
      style={{
        textIndent: 0,
        textTransform: "none",
        blockProgression: "tb",
      }}
      d="M6 7a2 2 0 00-2 2v82a2 2 0 002 2h88a2 2 0 100-4H8V9a2 2 0 00-2-2zm60 21c-1.245.007-2.106 1.137-2.094 2.094.05 1.047 1.047 1.955 2.094 1.906h11L47.937 59.313c-3.645-3.034-7.342-6.075-10.437-8.72a2.031 2.031 0 00-2.844-.093l-20 18c-.777.704-.828 2.067-.125 2.844.704.777 2.036.829 2.813.125L36.03 54.625l10.563 8.781c.729.721 2.036.736 2.781.031L80 34.657V46c-.015 1.057.943 2.031 2 2.031s2.015-.974 2-2.031V30c0-1.047-.953-2-2-2z"
      overflow="visible"
    />
  </svg>
);

export default AccountingIcon;
