import React from "react";
import { connect } from "react-redux";
import { CmsMainlayoutStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import { Route, Switch } from "react-router-dom";
import cmsRoutes from "routes/cmsRoutes";
import { Navbar, Sidebar } from "widgets/AdminSystem";
import { userService } from "apiService";
import { ROUTE_PATH } from "helpers";
class CmsMainlayout extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.checkIsLogin();
  }

  fetchProfile = async () => {
    const res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      if (res.data.admin) {
        this.props.setReduxUserAuth(res.data);
      } else {
        this.props.history.push("/unauthorized");
      }
    } else {
      this.props.history.push(ROUTE_PATH.LOGIN);
    }
  };

  checkIsLogin = async () => {
    let wowToken = localStorage.getItem("wowToken");
    if (wowToken) {
      let params = {
        access_token: localStorage.getItem("wowToken"),
      };
      const res = await userService.POST_CHECK_TOKEN_EXPIRE(params);
      if (res && res.success) {
        sessionStorage.setItem("wowLogin", true);
        this.fetchProfile();
      } else {
        this.props.history.push(ROUTE_PATH.LOGIN);
      }
    } else {
      this.props.history.push(ROUTE_PATH.LOGIN);
    }
  };

  openHamburger = () => this.setState({ isOpen: true });
  closeHamburger = () => this.setState({ isOpen: false });

  render() {
    return (
      <CmsMainlayoutStyled>
        <Sidebar cmsRoutes={cmsRoutes} isOpenHamburger={this.state.isOpen} closeHamburger={this.closeHamburger} />
        <Navbar toggleHamburger={this.openHamburger} />
        <div className="main-body">
          <div className="main-content">
            <Switch>
              {cmsRoutes &&
                cmsRoutes.map((e, i) => {
                  return !e.sub ? (
                    <Route
                      key={i}
                      exact={e.exact}
                      path={e.path}
                      component={e.component}
                    />
                  ) : (
                    e.subComponent.map((e2, i2) => (
                      <Route
                        key={i}
                        exact={e.exact}
                        path={e.path}
                        component={e.component}
                      />
                    ))
                  );
                })}
            </Switch>
          </div>
        </div>
      </CmsMainlayoutStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CmsMainlayout);
