import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AgentDetailContainerStyled } from "./styled";
import { setReduxUserAuth } from "actions";
import { adminService } from "apiService";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "helpers";
import { AgentProfileForm } from "forms/AdminFormSystem";
import { useHistory } from "react-router-dom";

const AgentDetailContainer = (props) => {
  const [agentID] = useState(props.isAdd ? "create" : props.detailId);
  const [data, setData] = useState();
  const [initialValues, setInitialValues] = useState();

  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      let res = await adminService.GET_AGENT_DETAIL(props.detailId);
      if (res && res.success) {
        setData(res.data);
        setInitialValues({
          ...res.data,
          user_id: res.data.user ? String(res.data.user.name) : undefined,
        });
      }
    };
    if (!props.isAdd) {
      fetchData();
      setData();
    } else {
      setInitialValues();
    }
  }, [agentID, props.isAdd, props.detailId]);

  const handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
    } = props;
    let params = {
      ...values,
      is_active: values.is_active ? values.is_active : 0,
    };

    if (values.user_id) {
      params.user_id =
        typeof values.user_id === "string" ? data.user.id : values.user_id.id;
    }
    if (typeof syncErrors === "object") {
      toast.error("กรุณากรอกข้อมูลให้ครบถ้วน", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (props.isAdd) {
        let res = await adminService.POST_AGENT_ADD(params);
        if (res && res.success) {
          toast.success("บันทึกสำเร็จ");
          props.handleAddData(res.data);
          props.handleClose();
        } else {
          toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      } else {
        let res = await adminService.PATCH_AGENT_UPDATE(props.detailId, params);
        if (res && res.success) {
          toast.success("บันทึกสำเร็จ");
          props.handleChange(res.data);
          props.handleClose();
        } else {
          toast.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      }
    }
  };

  const handdleClickDetail = (id) => {
    history.push(ROUTE_PATH.A_AGENCY);
  };

  const renderList = () => {
    return (
      <div style={{ display: "flex" }}>
        {data &&
          data.agencies.length > 0 &&
          data.agencies.map((e, i) => (
            <div
              key={i}
              className="show_agent_detail"
              onClick={() => handdleClickDetail(e.id)}
              // style={{ margin: 5 }}
            >
              <span style={{ padding: 10 }}>{i === 0 ? "" : ","}</span>
              {e.displayname}
            </div>
          ))}
      </div>
    );
  };

  return (
    <AgentDetailContainerStyled>
      <div className="form_item_layout">
        <AgentProfileForm
          initialValues={initialValues}
          handdleSave={handdleSave}
          isAdd={props.isAdd}
        />
        <h3>Company</h3>
        {props.isAdd ? null : (
          <div className="shwo_agency_layout">{renderList()}</div>
        )}
      </div>
    </AgentDetailContainerStyled>
  );
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AgentProfileForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentDetailContainer);
