import React, { useState, useEffect } from "react";
import { SidebarStyled } from "./styled";
import { NavLink } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ChevronRightIcon, CrossIcon } from "components";
import { iconImages } from "assets";

export const Sidebar = ({ cmsRoutes, isOpenHamburger, closeHamburger }) => {
  const [isShow, setisShow] = useState(false);
  const [, setWindowWidth] = useState(document.body.clientWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(document.body.clientWidth);
    });
  }, []);

  const toggleShow = () => setisShow(!isShow);

  const scrollTop = () => {
    sessionStorage.setItem("offsetY", 0);
    closeHamburger()
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <SidebarStyled isShow={isShow || isOpenHamburger}>
      {isOpenHamburger && (
        <div className="dismiss-section">
          <div onClick={closeHamburger} className="dismiss-button">
            <CrossIcon fill="rgba(0,0,0,0.5)" />
          </div>
        </div>
      )}
      <div className="header">
        {isShow || isOpenHamburger ? (
          <img className="img_header" src={iconImages["logo.png"]} alt="" />
        ) : (
          <img
            className="img_header2"
            src={iconImages["logo_web.png"]}
            alt=""
          />
        )}
      </div>
      <div className="nav-link-container">
        {cmsRoutes &&
          cmsRoutes
            .filter((item) => !item.notShow)
            .map((item, index) => {
              const IconComponent = item.icon;
              return (
                <NavLink
                  to={item.path}
                  className="menu"
                  activeClassName="active-menu"
                  key={index}
                  onClick={scrollTop}
                >
                  <div className="icon-wrapper">
                    <IconComponent size={30} />
                  </div>
                  <span className="menu-name">{item.name}</span>
                </NavLink>
              );
            })}
      </div>
      {!isOpenHamburger && (
        <label className="expand-side-bar" onClick={toggleShow}>
          <span>Collapse</span>
          <div className="chevron-wrapper">
            <ChevronRightIcon size={30} />
          </div>
        </label>
      )}
    </SidebarStyled>
  );
};
