import React from "react";
import { Field, reduxForm } from "redux-form";
import { GenreFormStyled, SwitchButton } from "./styled";
import {
  ButtonCustom,
  Input,
  Card,
} from "components";
// InputUploadFIleBase64
import { VALIDATE } from "helpers";

const Switch = ({ input }) => (
  <SwitchButton>
    <input
      type="checkbox"
      name={input.name}
      checked={input.value}
      onChange={input.onChange}
    />
    <div className="slider" />
  </SwitchButton>
);
class GenreForm extends React.Component {
  state = {};

  componentDidMount() {}

  renderForm = () => {
    return (
      <>
        <div className="box_label">
          <div className="label">Order No.</div>
          <Field
            theme_standard_2
            name="order"
            placeholder="Enter Order No."
            component={Input}
            validate={VALIDATE.REQUIRE}
          />
        </div>
        <div className="box_label">
          <div className="label">Slug</div>
          <Field
            theme_standard_2
            name="slug"
            placeholder="Enter Slug"
            component={Input}
            validate={VALIDATE.REQUIRE}
          />
        </div>
        <div className="box_label">
          <div className="label">Label</div>
          <Field
            theme_standard_2
            name="label"
            placeholder="Enter Label"
            component={Input}
            validate={VALIDATE.REQUIRE}
          />
        </div>
        <div className="box_label">
          <div className="label">Title</div>
          <Field
            theme_standard_2
            name="title"
            placeholder="Enter Title"
            component={Input}
            validate={VALIDATE.REQUIRE}
          />
        </div>
        <div className="box_label">
          <div className="label">Active</div>
          <Field theme_standard_2 name="is_active" component={Switch} />
        </div>
        <div className="button_layer">
          <ButtonCustom
            theme_yellow_2
            label="Save"
            onClick={this.props.handdleSave}
          />
        </div>
      </>
    );
  };

  checkisAdd = () => {
    return this.props.isAdd ? (
      this.renderForm()
    ) : (
      <Card title="Genre">{this.renderForm()}</Card>
    );
  };

  render() {
    return (
      <GenreFormStyled isAdd={this.props.isAdd}>
        {this.checkisAdd()}
      </GenreFormStyled>
    );
  }
}

export default reduxForm({
  form: "GenreForm", // a unique identifier for this form
  enableReinitialize: true,
})(GenreForm);
