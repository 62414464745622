import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Notification } from "components";
import Login from "containers/LoginContainer";
import { CmsMainLayout } from "mainLayouts";
import UnAuthorized from './UnAuthorized'
const indexRoutes = [
  {
    path: "/admin",
    exact: false,
    name: "admin",
    component: CmsMainLayout,
  },
  {
    path: "/unauthorized",
    exact: true,
    name: "unauthorized",
    component: UnAuthorized
  },
  {
    path: "/login",
    exact: false,
    name: "login",
    component: Login,
  },
];


class Routes extends React.Component {
  render() {
    return (
      <>
        <Notification />
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                exact={prop.exact}
                path={prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
          <Route
            exact={true}
            path="/"
            component={Index}

          />
        </Switch>
      </>
    );
  }
}

const Index = (props) => {
  let wowToken = localStorage.getItem('wowToken');
  if (wowToken) {
    return <Redirect to='/admin/creators' />;
  } else {
    return <Redirect to='/login' />;
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
