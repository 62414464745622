import React, { useState } from "react";
import { connect } from "react-redux";
import {
  GenreDetailContainerStyled,
  Table,
  ImagePreloadContainer,
} from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import {
  ButtonCustom,
  EditIcon,
  CrossIcon,
  CreatorIcon,
  ChevronRightIcon,
  SideContainer,
  NotFoundIcon,
} from "components";
import { ROUTE_PATH } from "helpers";
import { Helmet } from "react-helmet";
import GenreDetailContainer from "../GenreDetailContainer";
class GenreContainer extends React.Component {
  state = {
    dataGenre: [],
    isShowSidePanel: false,
    showPanelAccessor: "",
    openAddGenre: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { success, data } = await adminService.GET_GENRES_LIST();
    if (success) {
      this.setState({
        dataGenre: data,
      });
    }
  };

  handdleClickAddGenre = () => {
    this.setState({ openAddGenre: true });
  };

  handleCloseAddGenre = () => {
    this.setState({ openAddGenre: false });
  };
  handleAddSuccess = () => {
    this.fetchData();
    this.setState({ openAddGenre: false });
  };
  handdleClickEditGenre = (e) => {
    this.props.history.push(ROUTE_PATH.A_GENRE + "/" + e);
  };

  handdleClickCreatorDetail = (e) => {
    this.props.history.push(ROUTE_PATH.A_CREATOR + "/" + e);
  };

  showSidePanel = () => {
    this.setState({
      isShowSidePanel: true,
    });
  };

  dismissPanel = () => {
    this.setState({
      isShowSidePanel: false,
    });
  };

  handleClickGenre = async (e) => {
    const { success, data } = await adminService.GET_GENRES_DETAIL(e);
    if (success) {
      this.setState({
        dataListGenre: data,
        showPanelAccessor: e,
        openAddGenre: false,
      });

      this.showSidePanel();
    }
  };

  render() {
    const {
      dataGenre,
      dataListGenre,
      isShowSidePanel,
      showPanelAccessor,
      openAddGenre,
    } = this.state;
    return (
      <GenreDetailContainerStyled
        showSidePanel={isShowSidePanel && dataListGenre}
      >
        <Helmet>
          <title>admin</title>
        </Helmet>
        <>
          <div className="tab_layout">
            <div className="title_layout">Genre System</div>
            <div className="button_layer">
              <ButtonCustom
                theme_yellow_2
                label="+ New Genre"
                onClick={this.handdleClickAddGenre}
              />
            </div>
          </div>

          <div className="content">
            <Table>
              {dataGenre?.map((value, index) => (
                <label
                  className="genre-wrapper"
                  key={index}
                  onClick={() => this.handleClickGenre(value.slug)}
                >
                  <div className="genre-container">
                    <ImageWithPreload src={value.poster_photo_uri} />
                    <div className="overlay">
                      <span className="genre-name">{value.slug}</span>
                      <div className="creator-count-container">
                        <CreatorIcon fill="white" />
                        <span className="count">
                          {value.creators_count.toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <span className="ordering-number">#{value.order}</span>
                  </div>
                </label>
              ))}
            </Table>

            {dataListGenre && (
              <>
                <div className="information-panel">
                  <div className="dismiss-button" onClick={this.dismissPanel}>
                    <CrossIcon size={30} />
                  </div>
                  <span className="genre-name">{showPanelAccessor}</span>

                  <div className="information-section">
                    {dataListGenre?.map((value, index) => (
                      <label className="creator-wrapper" key={index}>
                        <input type="checkbox" />

                        {value.description && (
                          <div className="creator-detail">
                            <span className="title">Description</span>
                            <span className="detail">{value.description}</span>
                          </div>
                        )}

                        <div className="creator-record" key={index}>
                          {isShowSidePanel && value.description && (
                            <div className="chevron-wrapper">
                              <ChevronRightIcon size={20} />
                            </div>
                          )}
                          <div className="avatar" />
                          <span
                            className="display-name"
                            onClick={() =>
                              this.handdleClickCreatorDetail(value.id)
                            }
                          >
                            {value.displayname}
                          </span>
                        </div>
                      </label>
                    ))}
                    {dataListGenre.length === 0 && (
                      <div className="not-found-wrapper">
                        <NotFoundIcon />
                        <span>No Information</span>
                      </div>
                    )}
                  </div>

                  <div
                    onClick={() =>
                      this.handdleClickEditGenre(showPanelAccessor)
                    }
                    className="edit-button"
                  >
                    <div className="edit-icon-wrapper">
                      <EditIcon size={25} />
                    </div>
                    <span>Edit Genre</span>
                  </div>
                </div>
                <div className="opacity" onClick={this.dismissPanel} />
              </>
            )}
          </div>
        </>
        <>
          <SideContainer
            title="New Genre"
            isShow={openAddGenre}
            handleClose={this.handleCloseAddGenre}
          >
            <GenreDetailContainer
              isAdd={true}
              handleAddSuccess={this.handleAddSuccess}
            />
          </SideContainer>
        </>
      </GenreDetailContainerStyled>
    );
  }
}

const ImageWithPreload = ({ src }) => {
  const [loading, setIsLoading] = useState(true);
  return (
    <ImagePreloadContainer>
      {loading && <div className="preload">loading</div>}
      <img src={src} alt="genre" onLoad={() => setIsLoading(false)} />
    </ImagePreloadContainer>
  );
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenreContainer);
