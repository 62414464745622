import React from "react";

const OrderIcon = ({ size = 40, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -15 125 125"
    width={size}
    height={size}
    fill={fill}
  >
    <path d="M14.4 32h24.3V7.8H14.4V32zm4-20.2h16.3V28H18.4V11.8zM14.4 62.1h24.3V37.9H14.4v24.2zm4-20.2h16.3v16.3H18.4V41.9zM14.4 92.2h24.3V68H14.4v24.2zm4-20.2h16.3v16.3H18.4V72zM48.1 12.4h12v4h-12zM48.1 23.4h35.5v4H48.1zM48.1 42.5h12v4h-12zM48.1 53.5h35.5v4H48.1zM48.1 72.6h12v4h-12zM48.1 83.6h35.5v4H48.1z" />
    <path d="M24.9 19.5l-2-1.6-2.5 3.1 4.8 3.9 7-7.1-2.9-2.8z" />
  </svg>
);

export default OrderIcon;
