import React from "react";

const AgentIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 64 80"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M33.7 38.49h-4.83a4.79 4.79 0 00-4.79 4.78v10.16a4.79 4.79 0 004.79 4.78h4.83a4.79 4.79 0 004.79-4.78V43.27a4.79 4.79 0 00-4.79-4.78zm1.79 14.94a1.79 1.79 0 01-1.79 1.78h-4.83a1.79 1.79 0 01-1.79-1.78V43.27a1.79 1.79 0 011.79-1.78h4.83a1.79 1.79 0 011.79 1.78zM10.47 38.49H5.64a4.79 4.79 0 00-4.79 4.78v10.16a4.79 4.79 0 004.79 4.78h4.83a4.79 4.79 0 004.79-4.78V43.27a4.79 4.79 0 00-4.79-4.78zm1.79 14.94a1.79 1.79 0 01-1.79 1.78H5.64a1.79 1.79 0 01-1.79-1.78V43.27a1.79 1.79 0 011.79-1.78h4.83a1.79 1.79 0 011.79 1.78zM58.36 38.49h-4.83a4.79 4.79 0 00-4.79 4.78v10.16a4.79 4.79 0 004.79 4.78h4.83a4.79 4.79 0 004.79-4.78V43.27a4.79 4.79 0 00-4.79-4.78zm1.79 14.94a1.79 1.79 0 01-1.79 1.78h-4.83a1.79 1.79 0 01-1.79-1.78V43.27a1.79 1.79 0 011.79-1.78h4.83a1.79 1.79 0 011.79 1.78zM33.59 12.73L30 16.05l-.9-1.18a1.5 1.5 0 00-2.38 1.83l1.91 2.48a1.44 1.44 0 001.06.58h.13a1.49 1.49 0 001-.4l4.76-4.45a1.5 1.5 0 00-2-2.19z" />
    <path d="M31.28 5.79a10.26 10.26 0 1010.26 10.26A10.27 10.27 0 0031.28 5.79zm0 17.51a7.26 7.26 0 117.26-7.25 7.26 7.26 0 01-7.26 7.25zM19.13 14.55h-3.2a9.38 9.38 0 00-9.38 9.37v12.57h3V23.92a6.39 6.39 0 016.38-6.37h3.2a11.3 11.3 0 010-3zM31.28 28.3a12.59 12.59 0 01-1.5-.1v8.29h3V28.2a12.32 12.32 0 01-1.5.1zM54.45 23.92v12.57h3V23.92a9.38 9.38 0 00-9.38-9.37h-4.63a11.3 11.3 0 010 3h4.63a6.38 6.38 0 016.38 6.37z" />
  </svg>
);

export default AgentIcon;
