import styled, { keyframes } from "styled-components";
import { COLOR, SCREEN } from "assets";

const PopOverIn = keyframes`
from {
  transform: translateX(100vw);
  visibility: hidden;
}
to {
  transform: translateX(0);
  visibility: visible;
}
`;

const PopOverOut = keyframes`
0%{
    transform: translateX(0%);
    visibility: visible;
}
25%{
    transform: translateX(-10%);
}
100%{
    transform: translateX(150%);
    visibility: hidden;
}`;

const FadeIn = keyframes`
from{
  opacity: 0;
  visibility: hidden;
}
to{
  opacity: 1;
  visibility: visible;
}`;

const FadeOut = keyframes`
from{
  opacity: 1;
  visibility: visible;
}
to{
  opacity: 0;
  visibility: hidden;
}`;

export const OrderContainerStyled = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  height: fit-content;
  min-width: fit-content;
  display: flex;
  flex-direction: column;

  padding: 1rem 2rem;

  background-color: #f3f3f3;

  .page-header {
    margin-bottom: 10px;

    font-weight: bold;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: fit-content;
    min-width: 100%;
    justify-content: center;

    margin-top: 1rem;
  }
  .search-wrapper {
    min-width: 250px;
    width: 50%;
  }
  .menu-wrapper {
    display: flex;
    width: 50%;
    min-width: fit-content;

    margin-top: 1rem;
  }
  .menu {
    width: 25%;
    min-width: fit-content;

    border: 1px solid #d0d3cd;
    border-left: none;
    border-right: none;
    padding: 0.5rem;

    background-color: white;
    outline: none;
    white-space: nowrap;

    :first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-left: 1px solid #d0d3cd;
    }
    :last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-right: 1px solid #d0d3cd;
    }
  }
  .active {
    background-color: gold;
    border-color: gold !important;
  }
  .button_edit {
    padding: 2px 10px;
    font-size: 16px;
    background: #0059b3;
    color: white;
    font-weight: bold;
    border: 1px solid #0032b3;
    outline: none;
  }
  .div_right {
    text-align: right;
  }
  .div_center {
    text-align: center;
  }
  @media (max-width: ${SCREEN.PHONE}) {
    padding: 1rem;
    .content {
      justify-content: initial;
    }
  }
`;

export const CardTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
  max-width: 1800px;

  .card-container {
    display: flex;
    flex-direction: column;
    width: calc(33% - 16px);
    position: relative;

    margin: 8px;
    border-radius: 8px;

    overflow: hidden;
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }
  .due-date {
    margin-left: 4px;
  }
  .card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    padding: 1.5rem 1.5rem 0.5rem;
  }
  .avatar {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 30px;

    background-color: gray;
    color: white;
    text-transform: uppercase;
  }
  .gifter-name,
  .phone-number,
  .email {
    margin-left: 8px;
  }
  .card-body {
    flex: 1;
    position: relative;

    padding: 0.5rem 1.5rem 66px;
  }
  .section {
    display: flex;
    justify-content: space-between;

    margin-top: 8px;
    :first-child {
      margin: 0;
    }
    span {
      display: flex;
      align-items: center;
    }
  }
  .contact-wrapper {
    justify-content: initial;
  }
  .gifter-wrapper {
    justify-content: initial;
    align-items: center;
    position: relative;

    padding-right: 30px;
  }
  .calendar-icon-wrapper {
    width: 30px;
    height: 30px;
  }
  .phone-icon-wrapper,
  .email-icon-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brief-wrapper {
    flex-direction: column;
  }
  .brief-detail {
    min-height: 70px;
    position: relative;

    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;

    span {
      display: -webkit-box !important;
      height: 100%;

      outline: none;

      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .date {
    justify-content: initial;
    align-items: center;
  }
  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;

    padding: 2px 8px;
    border-radius: 16px;

    background-color: #eee;
  }
  .price-wrapper {
    position: absolute;
    bottom: 26px;
    height: initial;
    display: flex;
    align-items: center;

    border-radius: 4px;
  }
  .price {
    height: initial;

    border-radius: 4px;
    margin-left: 8px;

    :first-of-type {
      margin-left: 0;
    }
  }
  .order {
    background-color: gold;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    position: relative;

    padding: 0 12px;
    border-radius: 4px;
    span {
      text-transform: capitalize;
    }
  }
  .line {
    width: 100%;

    margin-top: 1rem;
    border: 1px dashed rgba(0, 0, 0, 0.05);
  }
  .pending {
    border: 2px solid orange;

    color: orange;
    svg {
      fill: orange;
    }
  }
  .completed {
    border: 2px solid #00aa88;

    color: #00aa88;
  }
  .new {
    border: 2px solid gold;

    color: gold;
  }
  .requested {
    border: 2px solid #00a8ff;

    color: #00a8ff;
    svg {
      fill: #00a8ff;
    }
  }
  .refunded {
    border: 2px solid gold;
    color: gold;
  }
  .rejected {
    border: 2px solid #cd5588;
    color: #cd5588;
  }
  .cancelled {
    border: 2px solid #ff5500;
    color: #ff5500;
  }
  .creator-name,
  .service-name,
  .catagory-name {
    color: #003c89;
    font-weight: bold;
  }
  .discount-number {
    text-decoration: line-through;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .card-container {
      width: calc(50% - 16px);
    }
  }
  @media (max-width: 1000px) {
    .card-container {
      width: calc(50% - 16px);
    }
  }
  @media (max-width: 750px) {
    .card-container {
      width: calc(100% - 16px);
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .card-container {
      width: calc(100% - 16px);
    }
  }
`;

export const PopOver = styled(CardTable)`
  display: flex;
  flex-wrap: initial;
  align-content: initial;
  flex: initial;
  max-width: initial;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  z-index: 12;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};

  .container-wrapper {
    position: relative;
    height: calc(100% - 2rem);
    width: 50%;
    display: flex;
    flex-direction: column;

    border-radius: 8px;

    background-color: white;
    z-index: 1;
    visibility: ${({ show }) => (show ? "visible" : "hidden")};
    animation: ${({ show }) => (show ? PopOverIn : PopOverOut)} 0.35s
      ease-in-out;
  }
  .close {
    position: absolute;
    top: 100%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    box-sizing: border-box;

    border-radius: 40px;

    transform: translate(-50%, -30px);
    background-color: white;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow: auto;
  }
  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);
    animation: ${({ show }) => (show ? FadeIn : FadeOut)} 0.35s ease-in-out;
  }
  .price {
    position: relative;
    bottom: initial;
    width: fit-content;
  }
  .card-body {
    flex: 1;
    position: relative;

    padding: 0.5rem 1.5rem 100px;
  }
  .textarea-brief-detail {
    display: flex;
    min-height: 120px;
    position: relative;

    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.5rem 1rem;
    margin-top: 8px;

    font-size: 16px;
    color: ${COLOR.BLACK_1};
    resize: none;
    font-family: "prompt_light";
  }
  .text-area-action {
    display: flex;
    justify-content: flex-end;

    margin-top: 0.5rem;
  }
  .submit-button,
  .cancel-button,
  .abort-button {
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;

    background-color: #0fcf6f;
    font-family: "prompt_light";
    font-weight: bold;
    color: white;
  }
  .cancel-button {
    background-color: transparent;
    color: gray;
  }
  .abort-button {
    background-color: red;
  }
  .edit-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .abort-wrapper {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    right: 1.5rem;
  }
  .abort {
    margin-top: 1rem;
  }
  .private-wrapper {
    margin-top: 1rem;
  }
  .price-wrapper-popover {
    height: initial;
    display: flex;
    align-items: center;

    border-radius: 4px;
  }
  .price-section {
    display: flex;
  }

  @media (max-width: ${SCREEN.TABLET}) {
    .container-wrapper {
      width: calc(70% - 16px);
    }
  }
  @media (max-width: 1000px) {
    .container-wrapper {
      width: calc(70% - 16px);
    }
  }
  @media (max-width: 700px) {
    .container-wrapper {
      width: calc(90% - 16px);
    }
  }
  @media (max-width: ${SCREEN.PHONE}) {
    .container-wrapper {
      width: calc(100% - 16px);
    }
  }
`;
