import React from "react";

const CompanyIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M80.333 78.583V42.417H63.667v3h13.666v33.166H60.5V18.417H19.667v60.166H14.25v3h71.5v-3h-5.417zm-22.833 0H22.667V21.417H57.5v57.166z" />
    <path d="M30.167 65.583H50v3H30.167z" />
  </svg>
);

export default CompanyIcon;
