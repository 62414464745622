export const COLOR = {
  WHITE_1: '#ffffff',
  WHITE_2: '#f6f7f9;',
  WHITE_3: 'rgb(255, 255, 255,0.5)',
  GREEN_1: '#1aa267', //Menu selected
  GREEN_2: '#2a7d59', //Layout Sidebar
  GREEN_3: '#0ac775', //Name of product,price,User,Logout,button submit
  GREEN_4: '#247f5c', //font of point {Barcodepage}
  GREEN_5: '#8db47b', //showmoney
  GREEN_6: '#59c3a0',
  GREY_1: '#5f5f5f', //Character
  GREY_2: '#d0d3cd', //Bordor
  GREY_3: '#9e9994', //เงินทอน
  GREY_4: '#e7e7e7', //shadow
  GREY_5: '#aaaaaa', // font of discount {Barcodepage}
  GREY_6: '#e3e3e3',
  GREY_7: '#f1f1f1',

  GREY_8: '#4b4b4b', //กล่องภายใน แทป ใน content
  GREY_9: '#f1f1f1', //backdrop
  GREY_10: '#464646', //ตัวหนังสือ
  GREY_11: '#e8e8e8',
  GREY_12: '#e9ebee',
  GREY_13: '#f8f9fb',
  GREY_14: '#ddd',
  GREY_15: '#cbcbcb',
  GREY_16: '#f8f8f8',
  GREY_17: '#cfd0d2',
  GREY_18: '#e6e6e6',
  GREY_19: '#adadad',
  GREY_20: '#666766',
  GREY_21: '#6e6f72',
  GREY_22: '#909090',
  GREY_23: '#303030',
  GREY_24: '#b9b9b9', //placeholder
  GREY_25: '#c7c7c7',
  GREY_26: '#f4f4f4',
  GREY_27: '#8e8e8e',
  ORANGE_1: '#ff7c00',
  ORANGE_2: '#ffc4a6',
  ORANGE_3: '#e67c00',
  RED_1: '#ff0000',
  RED_2: '#e24f3f', // font of Sum {Barcodepage}
  RED_3: '#90191f',
  BLACK_1: '#636363', // // font of change {Barcodepage}
  BLACK_2: '#565656',
  BLACK_3: '#333333',
  BLACK_4: '#000000',
  BLUE_1: 'blue',
  BLUE_2: '#40a9ff',
  BLUE_3: '#dee9ff',
  BLUE_4: '#4684ff',
  BLUE_5: '#0059b3',
  BLUE_6: '#24478f',
  BLUE_7: '#025196',
  YELLOW_1: '#fbcf4dfc',
  YELLOW_2: '#ffd11a',
  YELLOW_3: '#f9c20e',
  YELLOW_4: '#fbc210',
};

export const FONT = {
  SIZE_0_9: '9px',
  SIZE_0: '11px',
  SIZE_1: '12px',
  SIZE_2: '13px',
  SIZE_3: '14px',
  SIZE_4: '15px',
  SIZE_5: '16px',
  SIZE_6: '17px',
  SIZE_7: '18px',
  SIZE_8: '20px',
  SIZE_9: '24px',
  SIZE_10: '25px',
  SIZE_11: '28px',
  SIZE_12: '30px',
  SIZE_13: '32px',
  SIZE_14: '34px',
  SIZE_15: '37px',
  SIZE_16: '122px',
  SIZE_17: '9px',
  SIZE_20: '40px',
  SIZE_30: '50px',
  SIZE_40: '60px',
};

export const SCREEN = {
  PHONE: '500px',
  TABLET: '900px',
  LABTOP: '1366px',
};


// 360x640 (small mobile): 22.64%
// 1366x768 (average laptop): 11.98%
// 1920x1080 (large desktop): 7.35%
// 375x667 (average mobile): 5%
// 1440x900 (average desktop): 3.17%
// 720x1280 (large mobile): 2.74%
