import styled from 'styled-components'
import { COLOR } from 'assets';

export const Title = styled.div`
  padding : 10px 20px;
  color : ${props => props.headerColor ?? 'black'};;
  border-radius : 20px 20px 0px 0px;
  font-weight : bold;
  font-size : 20px;
  display : block;
  background-color : ${props => props.headerBg ?? COLOR.YELLOW_1};
  
`
export const StyledCard = styled.div`

  
  
  .card_container{
    padding : 0px;
    /* margin : 10px; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius : 20px;
  }
  
  .card_container:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .card_content{
    padding : 20px;
  }
`