import styled from 'styled-components';
import { COLOR, FONT } from 'assets';

export const ButtonCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    .button {
      cursor: pointer;
      font-size: ${FONT.SIZE_5};
      font-family: unicaOne_regular;
      color: ${COLOR.YELLOW_2};
      background: ${COLOR.BLUE_5};
      padding: 10px 30px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .theme_standard_2 {
    .button {
      cursor: pointer;
      font-size: ${FONT.SIZE_5};
      font-family: unicaOne_regular;
      color: ${COLOR.BLUE_5};
      background: ${COLOR.YELLOW_2};
    //   background: #fbc210;
    // color: #0059b3;
      padding: 10px 30px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .theme_haveFile {
    .button {
      cursor:${({ uploadFile }) => (uploadFile ? 'pointer' : ' not-allowed')};
      font-size: ${FONT.SIZE_5};
      font-family: unicaOne_regular;
      color: ${COLOR.YELLOW_2};
      background: ${COLOR.BLUE_5};
      padding: 10px 30px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .theme_yellow {
    .button {
      cursor:pointer;
      font-size: ${FONT.SIZE_2};
      font-family: unicaOne_regular;
      color: ${COLOR.BLUE_5};
      background: ${COLOR.YELLOW_2};
      padding: 5px 15px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .theme_yellow_2{
    .button {
      border-radius: 4px;
      padding: 6px 20px;
      font-size: 16px;
      background: ${COLOR.YELLOW_2};
      border: 1px solid ${COLOR.GREY_2};
      margin : 5px;
    }
  }
  .theme_white {
    .button {
      cursor: pointer;
      /* font-size: ${FONT.SIZE_5}; */
      font-family: unicaOne_regular;
      color: ${COLOR.WHITE_1};
      background: ${COLOR.BLUE_5};
      padding: 5px 10px;
      border: 1px solid ${COLOR.GREY_2};
      font-weight: 600;
      border-radius: 5px;
    }
  }
  .theme_delete{
    .button {
      border-radius: 4px;
      padding: 6px 20px;
      font-size: 16px;
      background: ${COLOR.ORANGE_1};
      border: 1px solid ${COLOR.GREY_2};
      margin : 5px;
      color : white;
    }
  }
`;
