import styled from "styled-components";

export const VoteContainerStyled = styled.div`
  position: relative;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 2rem;

  background-color: white;

  .tab_layout {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;

    padding-top: 1rem;

    .title_layout {
      font-weight: bold;
    }
    .button_layer {
    }
  }
  .table-wrapper {
    width: 100%;
    margin-top: 2rem;
    div:first-child {
      padding: 0;
    }
  }
  .div_right {
    text-align: right;
  }
`;
