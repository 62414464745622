import React from 'react';
import { connect } from 'react-redux';
import { GenreUploadContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { GenreUploadForm } from 'forms/AdminFormSystem';

class GenreUploadContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { slugId } = this.props;
    let res = await adminService.GET_ADMIN_GENRES_DETAIL(slugId);
    if (res && res.success) {
      let photo;
      let video;

      photo = res.data.poster_photo_uri ? res.data.poster_photo_uri : undefined;
      video = res.data.poster_video_uri ? res.data.poster_video_uri : undefined;
      this.setState({
        initialValues: {
          photo_file: photo,
          video_file: video,
        },
      });
    }
  };

  handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
      slugId,
    } = this.props;
    let params = {
      ...values,
    };
    this.setState({ loading: true })
    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        submitViewError: true,
      });
    } else {
      let formData = new FormData();
      if (params.photo_file && typeof params.photo_file === 'object')
        formData.append('photo_file', params.photo_file);
      if (params.video_file && typeof params.video_file === 'object')
        formData.append('video_file', params.video_file);
      let res = await adminService.UPLOAD_GENRE(slugId, formData);
      if (res && res.success) {
        toast.success('บันทึกสำเร็จ');
        this.fetchData();
      } else {
        toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
      }
    }
    this.setState({ loading: false })
  };
  render() {
    const { initialValues } = this.state;
    return (
      <GenreUploadContainerStyled>
        <GenreUploadForm
          initialValues={initialValues}
          handdleSave={this.handdleSave}
          loading={this.state.loading}
        />
      </GenreUploadContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.GenreUploadForm,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenreUploadContainer);
