import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AgencyProfileContainerStyled } from './styled';
import { adminService } from 'apiService';
import { toast } from 'react-toastify';
import { setReduxUserAuth } from 'actions';
import { AgencyProfileFormSec1 } from 'forms/AdminFormSystem';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const AgencyProfileContainer = (props) => {
  const [isAdd, setIsAdd] = useState(true)
  const [initialValues, setInitialValues] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const { agencyID } = props;
      let res = await adminService.GET_AGENCY_DETAIL(agencyID);
      if (res && res.success) {
        setInitialValues({ ...res.data })
      }
    };
    if (props.agencyID) {
      fetchData();
      setIsAdd(props.agencyID ? false : true)
    } else {
      setInitialValues()
    }
  }, [props.agencyID, isAdd, props])


  const handdleSave = async () => {
    const {
      reduxForm: { values, syncErrors },
      agencyID,
    } = props;
    let params = {
      ...values,
    };

    if (typeof syncErrors === 'object') {
      toast.error('กรุณากรอกข้อมูลให้ครบถ้วน', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (!agencyID) {
        let res = await adminService.POST_AGENCY_ADD(params);
        if (res && res.success) {
          props.handleClose()
          props.handleSaveSuccess(res.data)
          // this.setState({
          //   isAdd: false
          // })
          toast.success('บันทึกสำเร็จ');
          // callback && callback(res.data.id);
        } else {
          toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      } else {
        let res = await adminService.PATCH_AGENCY_UPDATE(agencyID, params);
        if (res && res.success) {
          props.handleEditSuccess()
          toast.success('แก้ไขข้อมูลสำเร็จ');
        } else {
          toast.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล');
        }
      }
    }
  };

  const handdleDelete = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handdleDeleteAgency(),
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handdleDeleteAgency = async () => {
    const { agencyID } = props;
    let res = await adminService.DELETE_AGENCY_DELETE(agencyID);
    if (res && res.success) {
      props.handleSaveSuccess(res.data)
      toast.success('ลบสำเร็จ');
    } else {
      toast.error('เกิดข้อผิดพลาดในการลบข้อมูล');
    }
  };

  return (
    <AgencyProfileContainerStyled>
      <AgencyProfileFormSec1
        initialValues={initialValues}
        handdleSave={handdleSave}
        handdleDelete={handdleDelete}
        isAdd={isAdd}
      />
    </AgencyProfileContainerStyled>
  );
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
  reduxForm: state.form.AgencyProfileFormSec1,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyProfileContainer);
