import React from "react";

const HamburgerMenuIcon = ({ size = 30, fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
  >
    <path d="M5 6h14a1 1 0 010 2H5a1 1 0 110-2zm0 5h14a1 1 0 010 2H5a1 1 0 010-2zm0 5h14a1 1 0 010 2H5a1 1 0 010-2z" />
  </svg>
);

export default HamburgerMenuIcon;
