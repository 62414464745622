import React from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { CreatorContainerStyled, InputSearch } from "./styled";
import { adminService } from "apiService";
import { setReduxUserAuth } from "actions";
import { ButtonCustom, SideContainer } from "components";
import CreatorCard from "./CreatorComponent/CreatorCard";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { iconImages } from "assets";
import CreatorProfileContainer from "../SectionsCreatorDetail/CreatorProfileContainer";
import { WindowScroller, List } from "react-virtualized";
const CreatorContainer = (props) => {
  const cardDiv = useRef();
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [showScroll] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(0);
  const [renderedData, setRenderedData] = useState([]);

  useEffect(() => {
    fetchData();
    setScroll(parseInt(sessionStorage.getItem("offsetY")));
    window.onload = function () {
      sessionStorage.setItem("offsetY", 0);
    };
  }, [showScroll, scroll]);

  const handleSearch = (e) => {
    if (e === "") {
      fetchData();
      console.log('fetch')
    } else {
      const cloneData = [...data];
      const filter = cloneData.filter((item) =>
        item.displayname.toUpperCase().includes(e.toUpperCase())
      );
      setRenderedData(filter);
    }
    setSearchInput(e);
  };

  const getFilterData = () => {
    if (searchInput !== "") {
      return renderedData.length === 0 ? [] : renderedData;
    } else {
      return renderedData;
    }
  };

  const fetchData = async () => {
    const { success, data } = await adminService.GET_CREATOR_LIST();
    if (success) {
      setData(data);
      setRenderedData(data);
    }
    return data;
  };

  const handdleClickAdd = (e) => setIsShow(true);

  const handleClose = () => setIsShow(false);

  const handleAddSuccess = () => {
    setSearchInput("");
    // setSearchData([]);
    handleClose();
    fetchData();
  };

  const scrollTop = () => {
    setScroll((prev) => prev + 1);
    setIsScroll(0);
    sessionStorage.setItem("offsetY", 0);
  };

  const about =
    "Mauris odio ipsum, feugiat a ultrices luctus, sodales ultricies libero. Nulla facilisi. Morbi interdum, dui nec sodales ullamcorper, massa metus facilisis mi, non luctus massa dolor ut leo.";
  const ITEMS_COUNT = getFilterData().length;
  const ITEM_SIZE = window.innerWidth <= 1024 ? 500 : 550;

  return (
    <CreatorContainerStyled>
      <Helmet>
        <title>admin</title>
      </Helmet>
      <div className="main_layout">
        <div className="search_layer">
          <InputSearch>
            <div className={"theme_mobile_standard"}>
              <input
                className="input"
                value={searchInput}
                placeholder="Search Creator"
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />
              <img
                className="icon"
                src={iconImages["search_icon.png"]}
                alt=""
              />
            </div>
          </InputSearch>
          <ButtonCustom
            theme_yellow_2
            label="+CREATOR"
            onClick={handdleClickAdd}
            className="button_add"
          />
        </div>
        <div className="search_result" style={{ paddingLeft: "2rem" }}>
          {searchInput === "" ? null : (
            <div>{`Search : "${searchInput}" ${renderedData.length} items`}</div>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {renderedData.length > 0 && (
            <WindowScroller>
              {({ height, width }) => {
                const itemsPerRow =
                  Math.floor(width / ITEM_SIZE) > 0
                    ? Math.floor(width / ITEM_SIZE)
                    : 1;
                const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);
                return (
                  <List
                    className="List"
                    width={width - 100}
                    height={height - 140}
                    rowCount={rowCount}
                    ref={cardDiv}
                    rowHeight={window.innerWidth < 500 ? 270 : 230}
                    scrollToIndex={scroll}
                    onScroll={() =>
                      setIsScroll((pre) => (pre < 5 ? pre + 1 : pre))
                    }
                    rowRenderer={({ index, key, style }) => {
                      const items = [];
                      const fromIndex = index * itemsPerRow;
                      const toIndex = Math.min(
                        fromIndex + itemsPerRow,
                        ITEMS_COUNT
                      );
                      for (let i = fromIndex; i < toIndex; i++) {
                        items.push(
                          <CreatorCard
                            key={i}
                            {...renderedData[i]}
                            about={about}
                            onClick={() => {
                              sessionStorage.setItem(
                                "offsetY",
                                cardDiv.current.Grid._renderedRowStopIndex
                              );
                              props.history.push(
                                `/admin/creators/${renderedData[i].id}`
                              );
                            }}
                          />
                        );
                      }
                      return (
                        <div className="Row" key={key} style={{ ...style }}>
                          {items}
                        </div>
                      );
                    }}
                  />
                );
              }}
            </WindowScroller>
          )}
        </div>
      </div>

      <SideContainer
        title={"Creator Profile"}
        isShow={isShow}
        handleClose={handleClose}
      >
        <CreatorProfileContainer
          isAdd={true}
          handleAddSuccess={handleAddSuccess}
        />
      </SideContainer>
      <div
        className="scroll_top"
        onClick={scrollTop}
        style={
          isScroll === 5 || sessionStorage.getItem("offsetY") !== "0"
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <span style={{ marginTop: 10, color: "white" }}>^</span>
      </div>
    </CreatorContainerStyled>
  );
};

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatorContainer));
