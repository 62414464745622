import styled from "styled-components";

export const AccountingContainerStyled = styled.div`
  position: relative;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 1rem 2rem;

  background-color: white;

  .tab_layout {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;

    padding-top: 1rem;

    .title_layout {
      font-weight: bold;
    }
    .button_layer {
    }
  }
  .table-wrapper {
    display: flex;
    position: relative;
    flex: 1;

    margin-top: 2rem;

    div:first-child {
      padding: 0;
      width: 100%;
    }
  }
  .no-data-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
  }
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    border-radius: 8px;

    background-color: #f8f8f8;

    span {
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }
`;
