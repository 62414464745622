import React from "react";

const TelephoneIcon = ({ fill = "black", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1000 -600 10000 10000"
    fillRule="evenodd"
    clipRule="evenodd"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    height={size}
    width={size}
    fill={fill}
  >
    <path d="M5742 7144c-1804 0-4419-2589-4419-4420 0-723 452-1401 1224-1401 162 0 893 1248 893 1402 0 252-749 620-749 843 0 542 1666 2208 2208 2208 223 0 591-749 843-749 47 0 1350 689 1390 807 276 642-754 1310-1390 1310z" />
  </svg>
);

export default TelephoneIcon;
