import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { BookingFormStyled } from './styled';
// import { iconImages } from 'assets';
// import { VALIDATE } from 'helpers';
import moment from 'moment';
// import { PaymentPopup } from 'widgets/PaymentsSystem';
import RefundContainer from 'containers/RefundContainer';
import { InputTextareaLabel } from 'components';

class BookingForm extends React.Component {
  state = {};

  render() {
    const { orderDetail, orderID, handleSubmit } = this.props;
    return (
      <BookingFormStyled>
        <div className="order_detail_layout">
          {orderDetail && (
            <>
              <div className="topic_layout">
                <div className="label">ครีเอเตอร์</div>
                <div className="text_right">
                  {orderDetail.creator.displayname}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">ชื่อผู้รับ</div>
                <div className="text_right">{orderDetail.giftee_name}</div>
              </div>
              <div className="topic_layout">
                <div className="label">ชื่อผู้สั่งซื้อ</div>
                <div className="text_right">
                  {orderDetail.gifter.displayname}
                </div>
              </div>
              <div className="topic_layout wrap">
                <div className="label">รายละเอียดงาน</div>
                <div className="text_right">
                  {orderDetail.status === 'NEW' ? (
                    <Field
                      theme_standard
                      name="brief"
                      component={InputTextareaLabel}
                      handleClickSuccess={handleSubmit}
                    />
                  ) : (
                    orderDetail.brief
                  )}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">ราคา</div>
                <div className="text_right">
                  {orderDetail.invoice.amount_coins.toLocaleString()} บาท
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">คำสั่งซื้อหมดอายุ</div>
                <div className="text_right">
                  {moment(orderDetail.created_at)
                    .add(7, 'days')
                    .format('DD/MM/YYYY')}
                </div>
              </div>
              <div className="topic_layout">
                <div className="label">จัดส่งไปยัง</div>
                <div className="text_right">
                  {orderDetail.order && orderDetail.contact_email}
                </div>
              </div>
              {orderDetail.status === 'NEW' && (
                <>
                  <div className="btn_pay_profile_layout">
                    <div className="btn_wrap">
                      {/* <PaymentPopup
                        invoiceID={orderDetail && orderDetail.invoice.id}
                        amount={orderDetail && orderDetail.invoice.amount_coins}
                      /> */}
                    </div>
                    <button
                      className="btn_cancel"
                      onClick={this.handleClickCancelOrder}
                    >
                      ยกเลิกคำสั่งจอง
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {orderDetail && orderDetail.status === 'REJECTED' && (
          <RefundContainer orderID={orderID} />
        )}
      </BookingFormStyled>
    );
  }
}

export default reduxForm({
  form: 'BookingForm', // a unique identifier for this form
  enableReinitialize: true,
})(BookingForm);
