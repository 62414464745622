import React from "react";
import { Container } from "./styled";

export const SwitchButton = ({
  onChange = () => {},
  ...props
}) => {
  return (
    <Container {...props}>
      <input
        type="checkbox"
        onChange={onChange}
        {...props}
      />
      <div className="slider" />
    </Container>
  );
};
